// Fonts
@import "sap-icons/sap-icons";

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'admin';

@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css";

#unsupported{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

#ebook{
    height: calc(100vh);
    > div{ height: 100%; }

    font-size: 1.35vw;

    @media (min-width: 1820px) {
        font-size: 27px;
    }
    @media (max-width: 600px) {
        font-size: 16px;
    }
    @media (max-width: 1120px) {
        font-size: 17px;
    }

    @import 'ebook/globals';
    @import 'ebook/navbar';
    @import 'ebook/video-modal';
    @import 'ebook/swiper';
    @import 'ebook/cover';
    @import 'ebook/chapter';
    @import 'ebook/pages';
}


.editor {
    > .container-fluid{ height: calc(100vh - 137px);}
    #ebook{
        font-size: calc(1.35vw * 0.6);
        height: 100%;
    }
}



