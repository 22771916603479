// Variables
// --------------------------

$sap-font-path:                "/fonts/sap-icons" !default;
$sap-font-size-base:           16px !default;
$sap-css-prefix:               sap !default;
$sap-version:                  "5.0.10" !default;
$sap-border-color:             #eee !default;
$sap-inverse:                  #fff !default;
$sap-li-width:                 2em !default;

// Convenience function used to set content property
@function sap-content($sap-var) {
  @return unquote("\"#{ $sap-var }\"");
}

// Row 1
$sap-var-accidental-leave:                  \e000;
$sap-var-account:                           \e001;
$sap-var-wrench:                            \e002;
$sap-var-windows-doors:                     \e003;
$sap-var-washing-machine:                   \e004;
$sap-var-visits:                            \e005;
$sap-var-video:                             \e006;
$sap-var-travel-expense:                    \e007;
$sap-var-temperature:                       \e008;
$sap-var-task:                              \e009;
$sap-var-synchronize:                       \e00a;
$sap-var-survey:                            \e00b;
$sap-var-settings:                          \e00c;
$sap-var-search:                            \e00d;
$sap-var-sales-document:                    \e00e;
$sap-var-retail-store:                      \e00f;


// Row 2
$sap-var-refresh:                           \e010;
$sap-var-product:                           \e011;
$sap-var-present:                           \e012;
$sap-var-ppt-attachment:                    \e013;
$sap-var-pool:                              \e014;
$sap-var-pie-chart:                         \e015;
$sap-var-picture:                           \e016;
$sap-var-photo-voltaic:                     \e017;
$sap-var-phone:                             \e018;
$sap-var-pending:                           \e019;
$sap-var-pdf-attachment:                    \e01a;
$sap-var-past:                              \e01b;
$sap-var-outgoing-call:                     \e01c;
$sap-var-opportunity:                       \e01d;
$sap-var-opportunities:                     \e01e;
$sap-var-notes:                             \e01f;


// Row 3
$sap-var-money-bills:                       \e020;
$sap-var-map:                               \e021;
$sap-var-log:                               \e022;
$sap-var-line-charts:                       \e023;
$sap-var-lightbulb:                         \e024;
$sap-var-leads:                             \e025;
$sap-var-lead:                              \e026;
$sap-var-laptop:                            \e027;
$sap-var-kpi-managing:                      \e028;
$sap-var-kpi-corporate-performance:         \e029;
$sap-var-incoming-call:                     \e02a;
$sap-var-inbox:                             \e02b;
$sap-var-horizontal-bar-chart:              \e02c;
$sap-var-history:                           \e02d;
$sap-var-heating-cooling:                   \e02e;
$sap-var-gantt-bars:                        \e02f;


// Row 4
$sap-var-future:                            \e030;
$sap-var-fridge:                            \e031;
$sap-var-fallback:                          \e032;
$sap-var-expense-report:                    \e033;
$sap-var-excel-attachment:                  \e034;
$sap-var-energy-saving-lightbulb:           \e035;
$sap-var-employee:                          \e036;
$sap-var-email:                             \e037;
$sap-var-edit:                              \e038;
$sap-var-duplicate:                         \e039;
$sap-var-download:                          \e03a;
$sap-var-doc-attachment:                    \e03b;
$sap-var-dishwasher:                        \e03c;
$sap-var-delete:                            \e03d;
$sap-var-decline:                           \e03e;
$sap-var-complete:                          \e03f;


// Row 5
$sap-var-competitor:                        \e040;
$sap-var-collections-management:            \e041;
$sap-var-chalkboard:                        \e042;
$sap-var-cart:                              \e043;
$sap-var-card:                              \e044;
$sap-var-camera:                            \e045;
$sap-var-calendar:                          \e046;
$sap-var-begin:                             \e047;
$sap-var-basket:                            \e048;
$sap-var-bar-chart:                         \e049;
$sap-var-attachment:                        \e04a;
$sap-var-arrow-top:                         \e04b;
$sap-var-arrow-right:                       \e04c;
$sap-var-arrow-left:                        \e04d;
$sap-var-arrow-bottom:                      \e04e;
$sap-var-approvals:                         \e04f;


// Row 6
$sap-var-appointment:                       \e050;
$sap-var-alphabetical-order:                \e051;
$sap-var-along-stacked-chart:               \e052;
$sap-var-alert:                             \e053;
$sap-var-addresses:                         \e054;
$sap-var-address-book:                      \e055;
$sap-var-add-filter:                        \e056;
$sap-var-add-favorite:                      \e057;
$sap-var-add:                               \e058;
$sap-var-activities:                        \e059;
$sap-var-action:                            \e05a;
$sap-var-accept:                            \e05b;
$sap-var-hint:                              \e05c;
$sap-var-group:                             \e05d;
$sap-var-check-availability:                \e05e;
$sap-var-weather-proofing:                  \e05f;


// Row 7
$sap-var-payment-approval:                  \e060;
$sap-var-batch-payments:                    \e061;
$sap-var-bed:                               \e062;
$sap-var-arobase:                           \e063;
$sap-var-family-care:                       \e064;
$sap-var-favorite:                          \e065;
$sap-var-navigation-right-arrow:            \e066;
$sap-var-navigation-left-arrow:             \e067;
$sap-var-e-care:                            \e068;
$sap-var-less:                              \e069;
$sap-var-lateness:                          \e06a;
$sap-var-lab:                               \e06b;
$sap-var-internet-browser:                  \e06c;
$sap-var-instance:                          \e06d;
$sap-var-inspection:                        \e06e;
$sap-var-image-viewer:                      \e06f;


// Row 8
$sap-var-home:                              \e070;
$sap-var-grid:                              \e071;
$sap-var-goalseek:                          \e072;
$sap-var-general-leave-request:             \e073;
$sap-var-create-leave-request:              \e074;
$sap-var-flight:                            \e075;
$sap-var-filter:                            \e076;
$sap-var-favorite-list:                     \e077;
$sap-var-factory:                           \e078;
$sap-var-endoscopy:                         \e079;
$sap-var-employee-pane:                     \e07a;
$sap-var-employee-approvals:                \e07b;
$sap-var-email-read:                        \e07c;
$sap-var-electrocardiogram:                 \e07d;
$sap-var-documents:                         \e07e;
$sap-var-decision:                          \e07f;


// Row 9
$sap-var-database:                          \e080;
$sap-var-customer-history:                  \e081;
$sap-var-customer:                          \e082;
$sap-var-credit-card:                       \e083;
$sap-var-create-entry-time:                 \e084;
$sap-var-contacts:                          \e085;
$sap-var-compare:                           \e086;
$sap-var-clinical-order:                    \e087;
$sap-var-chain-link:                        \e088;
$sap-var-pull-down:                         \e089;
$sap-var-cargo-train:                       \e08a;
$sap-var-car-rental:                        \e08b;
$sap-var-business-card:                     \e08c;
$sap-var-bar-code:                          \e08d;
$sap-var-folder-blank:                      \e08e;
$sap-var-passenger-train:                   \e08f;


// Row 10
$sap-var-question-mark:                     \e090;
$sap-var-world:                             \e091;
$sap-var-iphone:                            \e092;
$sap-var-ipad:                              \e093;
$sap-var-warning:                           \e094;
$sap-var-sort:                              \e095;
$sap-var-course-book:                       \e096;
$sap-var-course-program:                    \e097;
$sap-var-add-coursebook:                    \e098;
$sap-var-print:                             \e099;
$sap-var-save:                              \e09a;
$sap-var-play:                              \e09b;
$sap-var-pause:                             \e09c;
$sap-var-record:                            \e09d;
$sap-var-response:                          \e09e;
$sap-var-pushpin-on:                        \e09f;


// Row 11
$sap-var-pushpin-off:                       \e0a0;
$sap-var-unfavorite:                        \e0a1;
$sap-var-learning-assistant:                \e0a2;
$sap-var-timesheet:                         \e0a3;
$sap-var-time-entry-request:                \e0a4;
$sap-var-list:                              \e0a5;
$sap-var-action-settings:                   \e0a6;
$sap-var-share:                             \e0a7;
$sap-var-feed:                              \e0a8;
$sap-var-role:                              \e0a9;
$sap-var-flag:                              \e0aa;
$sap-var-post:                              \e0ab;
$sap-var-inspect:                           \e0ac;
$sap-var-inspect-down:                      \e0ad;
$sap-var-appointment-2:                     \e0ae;
$sap-var-target-group:                      \e0af;


// Row 12
$sap-var-marketing-campaign:                \e0b0;
$sap-var-message-error:                     \e0b1;
$sap-var-comment:                           \e0b2;
$sap-var-shipping-status:                   \e0b3;
$sap-var-collaborate:                       \e0b4;
$sap-var-shortcut:                          \e0b5;
$sap-var-lead-outdated:                     \e0b6;
$sap-var-tools-opportunity:                 \e0b7;
$sap-var-permission:                        \e0b8;
$sap-var-supplier:                          \e0b9;
$sap-var-table-view:                        \e0ba;
$sap-var-table-chart:                       \e0bb;
$sap-var-switch-views:                      \e0bc;
$sap-var-e-learning:                        \e0bd;
$sap-var-manager:                           \e0be;
$sap-var-switch-classes:                    \e0bf;


// Row 13
$sap-var-simple-payment:                    \e0c0;
$sap-var-signature:                         \e0c1;
$sap-var-sales-order-item:                  \e0c2;
$sap-var-sales-order:                       \e0c3;
$sap-var-request:                           \e0c4;
$sap-var-receipt:                           \e0c5;
$sap-var-puzzle:                            \e0c6;
$sap-var-process:                           \e0c7;
$sap-var-private:                           \e0c8;
$sap-var-popup-window:                      \e0c9;
$sap-var-person-placeholder:                \e0ca;
$sap-var-per-diem:                          \e0cb;
$sap-var-paper-plane:                       \e0cc;
$sap-var-paid-leave:                        \e0cd;
$sap-var-pdf-reader:                        \e0ce;
$sap-var-overview-chart:                    \e0cf;


// Row 14
$sap-var-overlay:                           \e0d0;
$sap-var-org-chart:                         \e0d1;
$sap-var-number-sign:                       \e0d2;
$sap-var-notification-2:                    \e0d3;
$sap-var-my-sales-order:                    \e0d4;
$sap-var-meal:                              \e0d5;
$sap-var-loan:                              \e0d6;
$sap-var-order-status:                      \e0d7;
$sap-var-customer-order-entry:              \e0d8;
$sap-var-performance:                       \e0d9;
$sap-var-menu:                              \e0da;
$sap-var-employee-lookup:                   \e0db;
$sap-var-education:                         \e0dc;
$sap-var-customer-briefing:                 \e0dd;
$sap-var-customer-and-contacts:             \e0de;
$sap-var-my-view:                           \e0df;


// Row 15
$sap-var-accelerated:                       \e0e0;
$sap-var-to-be-reviewed:                    \e0e1;
$sap-var-warning-2:                         \e0e2;
$sap-var-feeder-arrow:                      \e0e3;
$sap-var-quality-issue:                     \e0e4;
$sap-var-workflow-tasks:                    \e0e5;
$sap-var-create:                            \e0e6;
$sap-var-home-share:                        \e0e7;
$sap-var-globe:                             \e0e8;
$sap-var-tags:                              \e0e9;
$sap-var-work-history:                      \e0ea;
$sap-var-x-ray:                             \e0eb;
$sap-var-wounds-doc:                        \e0ec;
$sap-var-web-cam:                           \e0ed;
$sap-var-waiver:                            \e0ee;
$sap-var-vertical-bar-chart:                \e0ef;


// Row 16
$sap-var-upstacked-chart:                   \e0f0;
$sap-var-trip-report:                       \e0f1;
$sap-var-microphone:                        \e0f2;
$sap-var-unpaid-leave:                      \e0f3;
$sap-var-tree:                              \e0f4;
$sap-var-toaster-up:                        \e0f5;
$sap-var-toaster-top:                       \e0f6;
$sap-var-toaster-down:                      \e0f7;
$sap-var-time-account:                      \e0f8;
$sap-var-theater:                           \e0f9;
$sap-var-taxi:                              \e0fa;
$sap-var-subway-train:                      \e0fb;
$sap-var-study-leave:                       \e0fc;
$sap-var-stethoscope:                       \e0fd;
$sap-var-step:                              \e0fe;
$sap-var-sonography:                        \e0ff;


// Row 17
$sap-var-soccor:                            \e100;
$sap-var-physical-activity:                 \e101;
$sap-var-pharmacy:                          \e102;
$sap-var-official-service:                  \e103;
$sap-var-offsite-work:                      \e104;
$sap-var-nutrition-activity:                \e105;
$sap-var-newspaper:                         \e106;
$sap-var-monitor-payments:                  \e107;
$sap-var-map-2:                             \e108;
$sap-var-machine:                           \e109;
$sap-var-mri-scan:                          \e10a;
$sap-var-end-user-experience:               \e10b;
$sap-var-unwired:                           \e10c;
$sap-var-customer-financial-fact-sheet:     \e10d;
$sap-var-retail-store-manager:              \e10e;
$sap-var-Netweaver-business-client:         \e10f;


// Row 18
$sap-var-electronic-medical-record:         \e110;
$sap-var-eam-work-order:                    \e111;
$sap-var-customer-view:                     \e112;
$sap-var-crm-service-manager:               \e113;
$sap-var-crm-sales:                         \e114;
$sap-var-widgets:                           \e115;
$sap-var-commission-check:                  \e116;
$sap-var-collections-insight:               \e117;
$sap-var-clinical-tast-tracker:             \e118;
$sap-var-citizen-connect:                   \e119;
$sap-var-cart-approval:                     \e11a;
$sap-var-capital-projects:                  \e11b;
$sap-var-bo-strategy-management:            \e11c;
$sap-var-business-objects-mobile:           \e11d;
$sap-var-business-objects-explorer:         \e11e;
$sap-var-business-objects-experience:       \e11f;


// Row 19
$sap-var-bbyd-dashboard:                    \e120;
$sap-var-bbyd-active-sales:                 \e121;
$sap-var-business-by-design:                \e122;
$sap-var-business-one:                      \e123;
$sap-var-sap-box:                           \e124;
$sap-var-manager-insight:                   \e125;
$sap-var-accounting-document-verification:  \e126;
$sap-var-hr-approval:                       \e127;
$sap-var-idea-wall:                         \e128;
$sap-var-Chart-Tree-Map:                    \e129;
$sap-var-cart-5:                            \e12a;
$sap-var-cart-4:                            \e12b;
$sap-var-wallet:                            \e12c;
$sap-var-vehicle-repair:                    \e12d;
$sap-var-upload:                            \e12e;
$sap-var-unlocked:                          \e12f;


// Row 20
$sap-var-umbrella:                          \e130;
$sap-var-travel-request:                    \e131;
$sap-var-travel-expense-report:             \e132;
$sap-var-travel-itinerary:                  \e133;
$sap-var-time-overtime:                     \e134;
$sap-var-thing-type:                        \e135;
$sap-var-technical-object:                  \e136;
$sap-var-tag:                               \e137;
$sap-var-syringe:                           \e138;
$sap-var-syntax:                            \e139;
$sap-var-suitcase:                          \e13a;
$sap-var-simulate:                          \e13b;
$sap-var-shield:                            \e13c;
$sap-var-share-2:                           \e13d;
$sap-var-sales-quote:                       \e13e;
$sap-var-repost:                            \e13f;


// Row 21
$sap-var-provision:                         \e140;
$sap-var-projector:                         \e141;
$sap-var-add-product:                       \e142;
$sap-var-pipeline-analysis:                 \e143;
$sap-var-add-photo:                         \e144;
$sap-var-palette:                           \e145;
$sap-var-nurse:                             \e146;
$sap-var-sales-notification:                \e147;
$sap-var-mileage:                           \e148;
$sap-var-meeting-room:                      \e149;
$sap-var-media-forward:                     \e14a;
$sap-var-media-play:                        \e14b;
$sap-var-media-pause:                       \e14c;
$sap-var-media-reverse:                     \e14d;
$sap-var-media-rewind:                      \e14e;
$sap-var-measurement-document:              \e14f;


// Row 22
$sap-var-measuring-point:                   \e150;
$sap-var-measure:                           \e151;
$sap-var-map-3:                             \e152;
$sap-var-locked:                            \e153;
$sap-var-letter:                            \e154;
$sap-var-journey-arrive:                    \e155;
$sap-var-journey-change:                    \e156;
$sap-var-journey-depart:                    \e157;
$sap-var-it-system:                         \e158;
$sap-var-it-instance:                       \e159;
$sap-var-it-host:                           \e15a;
$sap-var-iphone-2:                          \e15b;
$sap-var-ipad-2:                            \e15c;
$sap-var-inventory:                         \e15d;
$sap-var-insurance-house:                   \e15e;
$sap-var-insurance-life:                    \e15f;


// Row 23
$sap-var-insurance-car:                     \e160;
$sap-var-initiative:                        \e161;
$sap-var-incident:                          \e162;
$sap-var-group-2:                           \e163;
$sap-var-goal:                              \e164;
$sap-var-functional-location:               \e165;
$sap-var-full-screen:                       \e166;
$sap-var-form:                              \e167;
$sap-var-fob-watch:                         \e168;
$sap-var-blank-tag:                         \e169;
$sap-var-family-protection:                 \e16a;
$sap-var-folder:                            \e16b;
$sap-var-fax-machine:                       \e16c;
$sap-var-example:                           \e16d;
$sap-var-eraser:                            \e16e;
$sap-var-employee-rejections:               \e16f;


// Row 24
$sap-var-drop-down-list:                    \e170;
$sap-var-draw-rectangle:                    \e171;
$sap-var-document:                          \e172;
$sap-var-doctor:                            \e173;
$sap-var-discussion-2:                      \e174;
$sap-var-discussion:                        \e175;
$sap-var-dimension:                         \e176;
$sap-var-customer-and-supplier:             \e177;
$sap-var-crop:                              \e178;
$sap-var-add-contact:                       \e179;
$sap-var-compare-2:                         \e17a;
$sap-var-color-fill:                        \e17b;
$sap-var-collision:                         \e17c;
$sap-var-curriculum:                        \e17d;
$sap-var-chart-axis:                        \e17e;
$sap-var-full-stacked-chart:                \e17f;


// Row 25
$sap-var-full-stacked-column-chart:         \e180;
$sap-var-vertical-bar-chart-2:              \e181;
$sap-var-horizontal-bar-chart-2:            \e182;
$sap-var-horizontal-stacked-chart:          \e183;
$sap-var-vertical-stacked-chart:            \e184;
$sap-var-choropleth-chart:                  \e185;
$sap-var-geographic-bubble-chart:           \e186;
$sap-var-multiple-radar-chart:              \e187;
$sap-var-radar-chart:                       \e188;
$sap-var-crossed-line-chart:                \e189;
$sap-var-multiple-line-chart:               \e18a;
$sap-var-multiple-bar-chart:                \e18b;
$sap-var-line-chart:                        \e18c;
$sap-var-line-chart-dual-axis:              \e18d;
$sap-var-bubble-chart:                      \e18e;
$sap-var-scatter-chart:                     \e18f;


// Row 26
$sap-var-multiple-pie-chart:                \e190;
$sap-var-column-chart-dual-axis:            \e191;
$sap-var-tag-cloud-chart:                   \e192;
$sap-var-area-chart:                        \e193;
$sap-var-cause:                             \e194;
$sap-var-cart-3:                            \e195;
$sap-var-cart-2:                            \e196;
$sap-var-bus-public-transport:              \e197;
$sap-var-burglary:                          \e198;
$sap-var-building:                          \e199;
$sap-var-border:                            \e19a;
$sap-var-bookmark:                          \e19b;
$sap-var-badge:                             \e19c;
$sap-var-attachment-audio:                  \e19d;
$sap-var-attachment-video:                  \e19e;
$sap-var-attachment-html:                   \e19f;


// Row 27
$sap-var-attachment-photo:                  \e1a0;
$sap-var-attachment-e-pub:                  \e1a1;
$sap-var-attachment-zip-file:               \e1a2;
$sap-var-attachment-text-file:              \e1a3;
$sap-var-add-equipment:                     \e1a4;
$sap-var-add-activity:                      \e1a5;
$sap-var-activity-individual:               \e1a6;
$sap-var-activity-2:                        \e1a7;
$sap-var-add-activity-2:                    \e1a8;
$sap-var-activity-items:                    \e1a9;
$sap-var-activity-assigned-to-goal:         \e1aa;
$sap-var-status-completed:                  \e1ab;
$sap-var-status-error:                      \e1ac;
$sap-var-status-inactive:                   \e1ad;
$sap-var-status-critical:                   \e1ae;
$sap-var-blank-tag-2:                       \e1af;


// Row 28
$sap-var-cart-full:                         \e1b0;
$sap-var-locate-me:                         \e1b1;
$sap-var-paging:                            \e1b2;
$sap-var-company-view:                      \e1b3;
$sap-var-document-text:                     \e1b4;
$sap-var-explorer:                          \e1b5;
$sap-var-personnel-view:                    \e1b6;
$sap-var-sorting-ranking:                   \e1b7;
$sap-var-drill-down:                        \e1b8;
$sap-var-drill-up:                          \e1b9;
$sap-var-vds-file:                          \e1ba;
$sap-var-sap-logo-shape:                    \e1bb;
$sap-var-folder-full:                       \e1bc;
$sap-var-system-exit:                       \e1bd;
$sap-var-system-exit-2:                     \e1be;
$sap-var-close-command-field:               \e1bf;


// Row 29
$sap-var-open-command-field:                \e1c0;
$sap-var-sys-enter-2:                       \e1c1;
$sap-var-sys-enter:                         \e1c2;
$sap-var-sys-help-2:                        \e1c3;
$sap-var-sys-help:                          \e1c4;
$sap-var-sys-back:                          \e1c5;
$sap-var-sys-back-2:                        \e1c6;
$sap-var-sys-cancel:                        \e1c7;
$sap-var-sys-cancel-2:                      \e1c8;
$sap-var-open-folder:                       \e1c9;
$sap-var-sys-find-next:                     \e1ca;
$sap-var-sys-find:                          \e1cb;
$sap-var-sys-monitor:                       \e1cc;
$sap-var-sys-prev-page:                     \e1cd;
$sap-var-sys-first-page:                    \e1ce;
$sap-var-sys-next-page:                     \e1cf;



// Row 30
$sap-var-sys-last-page:                     \e1d0;
$sap-var-generate-shortcut:                 \e1d1;
$sap-var-create-session:                    \e1d2;
$sap-var-display-more:                      \e1d3;
$sap-var-enter-more:                        \e1d4;
$sap-var-zoom-in:                           \e1d5;
$sap-var-zoom-out:                          \e1d6;
$sap-var-header:                            \e1d7;
$sap-var-detail-view:                       \e1d8;
$sap-var-collapse:                          \e1d9;
$sap-var-expand:                            \e1da;
$sap-var-positive:                          \e1db;
$sap-var-negative:                          \e1dc;
$sap-var-display:                           \e1dd;
$sap-var-menu2:                             \e1de;
$sap-var-redo:                              \e1df;


// Row 31
$sap-var-undo:                              \e1e0;
$sap-var-navigation-up-arrow:               \e1e1;
$sap-var-navigation-down-arrow:             \e1e2;
$sap-var-down:                              \e1e3;
$sap-var-up:                                \e1e4;
$sap-var-shelf:                             \e1e5;
$sap-var-background:                        \e1e6;
$sap-var-resize:                            \e1e7;
$sap-var-move:                              \e1e8;
$sap-var-show:                              \e1e9;
$sap-var-hide:                              \e1ea;
$sap-var-nav-back:                          \e1eb;
$sap-var-error:                             \e1ec;
$sap-var-slim-arrow-right:                  \e1ed;
$sap-var-slim-arrow-left:                   \e1ee;
$sap-var-slim-arrow-down:                   \e1ef;


// Row 32
$sap-var-slim-arrow-up:                     \e1f0;
$sap-var-forward:                           \e1f1;
$sap-var-overflow:                          \e1f2;
$sap-var-value-help:                        \e1f3;
$sap-var-multi-select:                      \e1f4;
$sap-var-exit-full-screen:                  \e1f5;
$sap-var-sys-add:                           \e1f6;
$sap-var-sys-minus:                         \e1f7;
$sap-var-dropdown:                          \e1f8;
$sap-var-expand-group:                      \e1f9;
$sap-var-vertical-grip:                     \e1fa;
$sap-var-horizontal-grip:                   \e1fb;
$sap-var-sort-descending:                   \e1fc;
$sap-var-sort-ascending:                    \e1fd;
$sap-var-arrow-down:                        \e1fe;
$sap-var-legend:                            \e1ff;


// Row 33
$sap-var-collapse-group:                    \e200;
$sap-var-message-warning:                   \e201;
$sap-var-message-information:               \e202;
$sap-var-message-success:                   \e203;
$sap-var-restart:                           \e204;
$sap-var-stop:                              \e205;
$sap-var-add-process:                       \e206;
$sap-var-cancel-maintenance:                \e207;
$sap-var-activate:                          \e208;
$sap-var-resize-horizontal:                 \e209;
$sap-var-resize-vertical:                   \e20a;
$sap-var-connected:                         \e20b;
$sap-var-disconnected:                      \e20c;
$sap-var-edit-outside:                      \e20d;
$sap-var-key:                               \e20e;
$sap-var-minimize:                          \e20f;


// Row 34
$sap-var-back-to-top:                       \e210;
$sap-var-hello-world:                       \e211;
$sap-var-outbox:                            \e212;
$sap-var-donut-chart:                       \e213;
$sap-var-heatmap-chart:                     \e214;
$sap-var-horizontal-bullet-chart:           \e215;
$sap-var-vertical-bullet-chart:             \e216;
$sap-var-call:                              \e217;
$sap-var-download-from-cloud:               \e218;
$sap-var-upload-to-cloud:                   \e219;
$sap-var-jam:                               \e21a;
$sap-var-sap-ui5:                           \e21b;
$sap-var-message-popup:                     \e21c;
$sap-var-cloud:                             \e21d;
$sap-var-horizontal-waterfall-chart:        \e21e;
$sap-var-vertical-waterfall-chart:          \e21f;


// Row 35
$sap-var-broken-link:                       \e220;
$sap-var-headset:                           \e221;
$sap-var-thumb-up:                          \e222;
$sap-var-thumb-down:                        \e223;
$sap-var-multiselect-all:                   \e224;
$sap-var-multiselect-none:                  \e225;
$sap-var-scissors:                          \e226;
$sap-var-sound:                             \e227;
$sap-var-sound-loud:                        \e228;
$sap-var-sound-off:                         \e229;
$sap-var-date-time:                         \e22a;
$sap-var-user-settings:                     \e22b;
$sap-var-key-user-settings:                 \e22c;
$sap-var-developer-settings:                \e22d;
$sap-var-text-formatting:                   \e22e;
$sap-var-bold-text:                         \e22f;


// Row 36
$sap-var-italic-text:                       \e230;
$sap-var-underline-text:                    \e231;
$sap-var-text-align-justified:              \e232;
$sap-var-text-align-left:                   \e233;
$sap-var-text-align-center:                 \e234;
$sap-var-text-align-right:                  \e235;
$sap-var-bullet-text:                       \e236;
$sap-var-numbered-text:                     \e237;
$sap-var-co:                                \e238;
$sap-var-ui-notifications:                  \e239;
$sap-var-bell:                              \e23a;
$sap-var-cancel-share:                      \e23b;
$sap-var-write-new-document:                \e23c;
$sap-var-write-new:                         \e23d;
$sap-var-cancel:                            \e23e;
$sap-var-screen-split-one:                  \e23f;


// Row 37
$sap-var-screen-split-two:                  \e240;
$sap-var-screen-split-three:                \e241;
$sap-var-customize:                         \e242;
$sap-var-user-edit:                         \e243;
$sap-var-source-code:                       \e244;
$sap-var-copy:                              \e245;
$sap-var-paste:                             \e246;
$sap-var-line-chart-time-axis:              \e247;
$sap-var-clear-filter:                      \e248;
$sap-var-reset:                             \e249;
$sap-var-trend-up:                          \e24a;
$sap-var-trend-down:                        \e24b;
$sap-var-cursor-arrow:                      \e24c;
$sap-var-add-document:                      \e24d;
$sap-var-create-form:                       \e24e;
$sap-var-resize-corner:                     \e24f;


// Row 38
$sap-var-chevron-phase:                     \e250;
$sap-var-chevron-phase-2:                   \e251;
$sap-var-rhombus-milestone:                 \e252;
$sap-var-rhombus-milestone-2:               \e253;
$sap-var-circle-task:                       \e254;
$sap-var-circle-task-2:                     \e255;
$sap-var-project-definition-triangle:       \e256;
$sap-var-project-definition-triangle-2:     \e257;
$sap-var-master-task-triangle:              \e258;
$sap-var-master-task-triangle-2:            \e259;
$sap-var-program-triangles:                 \e25a;
$sap-var-program-triangles-2:               \e25b;
$sap-var-mirrored-task-circle:              \e25c;
$sap-var-mirrored-task-circle-2:            \e25d;
$sap-var-checklist-item:                    \e25e;
$sap-var-checklist-item-2:                  \e25f;


// Row 39
$sap-var-checklist:                         \e260;
$sap-var-checklist-2:                       \e261;
$sap-var-chart-table-view:                  \e262;
$sap-var-filter-analytics:                  \e263;
$sap-var-filter-facets:                     \e264;
$sap-var-filter-fields:                     \e265;
$sap-var-indent:                            \e266;
$sap-var-outdent:                           \e267;
$sap-var-heading1:                          \e268;
$sap-var-heading2:                          \e269;
$sap-var-heading3:                          \e26a;
$sap-var-decrease-line-height:              \e26b;
$sap-var-increase-line-height:              \e26c;
$sap-var-fx:                                \e26d;
$sap-var-add-folder:                        \e26e;
$sap-var-away:                              \e26f;


// Row 40
$sap-var-busy:                              \e270;
$sap-var-appear-offline:                    \e271;
$sap-var-blur:                              \e272;
$sap-var-pixelate:                          \e273;
$sap-var-horizontal-combination-chart:      \e274;
$sap-var-add-employee:                      \e275;
$sap-var-text-color:                        \e276;
$sap-var-browse-folder:                     \e277;
$sap-var-primary-key:                       \e278;
$sap-var-two-keys:                          \e279;
$sap-var-strikethrough:                     \e27a;
$sap-var-text:                              \e27b;
$sap-var-responsive:                        \e27c;
$sap-var-desktop-mobile:                    \e27d;
$sap-var-table-row:                         \e27e;
$sap-var-table-column:                      \e27f;


// Row 41
$sap-var-validate:                          \e280;
$sap-var-keyboard-and-mouse:                \e281;
$sap-var-touch:                             \e282;