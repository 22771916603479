

//font sizes
.inner{
    padding: 3rem 3rem 1rem 3rem;
    h1{ font-size: 2em; @extend .bold; margin-bottom: 2.5rem; }
    h2{ font-size: 0.81em; text-transform: uppercase; }
}

.intro{ margin-bottom: 2.5rem;
    p{ font-size:0.95em; }
    p:last-child{ margin-bottom: 0;}
}
p{ font-size:0.74em; line-height: 1.35; }
a.btn{ font-size:1.1em; }


.min-h-offer{
    min-height: 11em;
}
.offer-box{

    padding:25px;
    background: $blue;
    color: white;

    h3{ font-size: 0.89em; @extend .bold; }
    p{ margin-bottom: 0.3em;}
    a{ color: white; }
    a:hover{ color: white; text-decoration: underline; }

    &.white{
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
        background: white;
        p,h3{ color: $black; }
        a{ color: $blue; }

    }

}

.pizza{ padding:0 10px 0 25px; position: relative; font-size: 1.68em;
    &:before,&:after{
        @extend .pizza;
        content: '"';
        color: $yellow;
        font-size: 2em;
        line-height: 0;
        height: 0;
        position: absolute;
        padding: 0;
    }
    &:before{
        left: 0;
        top:15px;
    }
    &:after{
        right: 0;
        margin-top: -25px;
    }
}


@media (max-width: 850px) {
    .inner{
        padding: 2rem 2rem 1rem 2rem;
        h1{ font-size: 1.6em;  margin-bottom: 1.5rem; }
    }
}
