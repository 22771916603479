
#top-nav{
    position: relative;
    width: 100%;
    background: #000;
    color: #ffffff;
    .nav-content{ margin: 12px 16px 12px 16px; }
    #top-nav-left{ float: left; }
    #top-nav-right{ width: 30%; float: right; text-align: right; }
    #top-nav-right img{ max-width: 130px; margin-top: 5px }
    .navbar-brand{ margin: 0px 0px; padding-left: 0; padding-right: 0;}
    ul{margin: 1em 0;padding-left: 0em;max-height: 40px;}
    ul:first-of-type{ border-left:none; /*border-left: 1px solid #fff;*/ margin-left: 2em;
        li{ margin: 12px 0px; font-family: 'BentonSans'; font-size: 1.1em; line-height: 1; color: white!important;}
        //li.medium-text{font-size: 1em; font-family: 'BentonSans-Bold';}
    }
    a {color: #fff;}
    a:hover{ background:     none; text-decoration: underline; }
    .navbar-nav{ margin-left: -15px; }

}
.navbar{border-radius: 0; }

.editor-box{
    min-height: calc(100vh - 140px)!important;
    height: 100%;
    overflow: auto;
    position: absolute;
    top:0;
    left: 15px;
    right:0px;
    padding-right: 15px;
    box-shadow: 5px 0 0 rgba(0,0,0,0.3);
}

.cropper-bg{
    background: none;
    width: 100%;
    height: 100%;
}
.cropper-modal{
    background: none;
}
.cropper-view-box{
   // outline: none;
}
.cropper-face, .cropper-line, .cropper-point{
    opacity: 0;
}

.btn{ min-width: 90px;
    i{ margin-top: 3px;}
}

.activeImage{
    border: 3px $blue solid;
}

#pages{
    .keyline{
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #dee2e6;
    }
    .keyline:last-of-type{ border: none; }
    a{ color: $black;
        &:hover{
            color: $blue; text-decoration: none;
        }
    }
}
