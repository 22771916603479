
.container{
    width: 100%;
    max-width: 100%;
    padding:0 30px;
}
.container-fluid{
    padding:0px;
}

.fixed-container{
    height: 0; padding-bottom: 56%; position:relative; overflow: hidden;
    .inner-container{ position: absolute; top: 0; bottom:0; left: 0; right: 0; }
}

.prev-page,.next-page{
    background: $primary;
    padding: 10px 12px;
    position: absolute;
    display: block;
    top:50%;
    transform: translateY(-50%);
    z-index: 9;
    line-height: 0;
    i{ color:white; }
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    display:none;
    cursor:pointer;

}
.prev-page{
    left: 0px;
    padding-left: 10px;
}
.next-page{
    right:0px;
    padding-right: 10px;
}
//h2
//h3
//h4
//h5
//p,li

//:root {
//    font-size: calc(1.2vw + 1.2vh + 1.2vmin);
//    @media (min-width: $medium) {
//        font-size: calc(0.8vw + 0.8vh + .5vmin);
//    }
//    @media (min-width: 1650px) and (min-height: 800px) {
//        font-size: 27px;
//    }
//}

span{
    color: theme-color('white') ;
}

.swiper-wrapper { height: 100%!important; }

.swiper-container {
    //height: 100%!important;
    height: calc(100% - 62px)!important;
}

.swiper-slide{
    height: 100%!important;
    overflow: auto;
    background-color: white;

    > section {
        height: 100%;
    }
}

// link within text
.text-link{
    color:$blue;
    text-decoration: underline;
}
.text-black{
    color:$black;
}
.no-underline{
    text-decoration: none;
}
.text-white {
    color: $white;
}
.text-link-blue {
    color: $blue;
}


// backgrounds white and black
.black-bg{
    background: $black;
}
.white-bg{
    background: $white;
}


// style for a links
a:hover{
    text-decoration: none;
}
a:active{
    text-decoration: none;
}
a:focus{
    text-decoration: none;
}

// Resposive Image
img{
    width:100%;
    height: auto;
}

// Background Cover
.background-cover{
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
}
.background-contain{
    background-repeat:no-repeat;
    background-size:contain;
    background-position:center;
}

.background-center{ background-position: center center; }

.background-top-center{ background-position: top center; }
.background-top-left{ background-position: top left; }
.background-top-right{ background-position: top right; }

.background-bottom-center{ background-position: bottom center; }
.background-bottom-right{ background-position: bottom right; }
.background-bottom-left{ background-position: bottom left; }

.background-center-right{ background-position: right center; }
.background-center-left{ background-position: left center; }

@media (max-width: 991px) {
    .no-mobile-background{ background: none!important; }
}
.modal {
    .modal-dialog {
        margin-top: 74px; // Header height
        margin-bottom: 100px; // Footer height
        width: 100vw !important;

        @media(max-width: 767px) {
            margin-top: 70px;
            margin-left: 0;
            margin-right: 0;
        }

        .modal-content {
            max-height: calc(100vh - (174px)); // viewport height minus header and footer combined
            height: 100vh;
            box-shadow: none;
            border-radius: 0;
            border: none;

            @media(max-width: 767px) {
                max-height: calc(100vh - (170px)); // viewport height minus header and footer combined
                height: 100vh;
            }

            .container {
                position: relative;
            }

            .modal-body {
                >div {
                    padding-top: 36px;
                }
            }
            .close-modal {
                position: absolute;
                top: 26px;
                right: 36px;
                cursor: pointer;
                color: black;
            }
        }
    }
}

.btn{
    padding: 9px 25px;
}
.btn-link {
    font-weight: bold;
}

sup {
    top: 0;
    left: 0;
}


//@media screen and (max-width: 767px) {
//    _::-webkit-full-page-media, _:future, :root body {
//        padding-bottom: 65px; //resize
//    }
//}
