.video-modal {
    &.modal {
        background: none;
        border: 0;

        .modal-dialog {
            width: 80%;
            max-width: 1200px;

            .modal-content {
                background: none;
                border: 0;

                .modal-header {
                    padding: 0;
                    border: 0;

                    .close {
                        padding: 1rem;
                        margin: -1rem -1rem -1rem auto;

                        i {
                            font-size: 1.5rem;
                        }
                    }
                }

                .modal-body {
                    height: 0;
                    padding-bottom: 56.5%;
                    position: relative;
                    flex: none;

                    iframe {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
    }
}
