.chapter{
    h1{ font-size: 2.7777em; }
    h2{ font-size: 1.03em; }
    .btn{ font-size: 1.1em; }
    .next-chapter{ position: absolute; top: 50%; right: 0; transform: translateY(-50%);
        line-height: 1.25;
        padding-top: 13px;
        padding-bottom: 13px;
    }
}

@media (max-width:850px) {
    .hasMobile{ display: none; }
    .chapter{
        h1{ font-size: 1.5em; }
        h2{ font-size: 0.8em; }

        .image-wrap{
            right: 0;
            width: 100%;
            height: 38% !important;
            bottom: 0;
        }
        .next-chapter{ position: absolute; top:30px; right: 0; transform: translateY(0%); z-index: 99 }
    }
}
