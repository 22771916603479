header{
    background:white;
    padding: 13px 30px;
    //border-bottom: 1px solid rgba(0,0,0, .2);
    position: relative;
    z-index: 10;
    height: 62px;
    position: relative;

    > div {
        height: 100%;

        > div {
            height: 100%;
        }
    }

    .main-logo{
        position: absolute;
        display: block;
        margin-left: 45px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;

        @media(max-width: 767px) {
            margin-left: 15px;
            left: 0px;
        }
        //margin: 14px 0 0 36px;
        a {
            position: relative;
            display: block;
            line-height: 1;
            font-size: 0.9vw;
            @media (max-width:1300px) {
                font-size: 12px;
            }
            @extend .medium;
            color: theme-color('white');
            img{
                width:148px;
            }
        }
    }
    .additional-nav{ line-height: 36px;
        .next,.prev{
            color: $blue;
            display: inline-block;
            font-size: 20px;
            vertical-align: middle;

        }
        .seperator{
            vertical-align: middle;
            display: inline-block;
            color: $black;
            font-size: 13px;
            margin-top: -5px;
            margin-right: 5px;
            margin-left: 5px;
        }

    }
}

.hamburger-menu {
    z-index:2;
    float: right;
    margin-right: 28px;
    display: block;
    position: relative;
    width: 47px;
    cursor: pointer;
    z-index: 4;
    height: 35px;
    background: white;
    margin-top: 2px;
    @media(max-width: 767px) {
        margin-right: 15px;
    }
}

.bar,
.bar:after,
.bar:before {
    width: 40px;
    height: 4px;
    border-radius: 9px;
}

.bar {
    position: relative;
    background: $primary;
    transition: all 0ms 300ms;
    top: 50%;;
    transform: translateY(-50%);
}

.bar.animate {
    background: rgba(255,255,255,0);
}

.bar:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 11px;
    background: $primary;
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.bar:after {
    content: "";
    position: absolute;
    left: 0;
    top: 11px;
    background: $primary;
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.bar.animate:after {
    top: 0;
    transform: rotate(45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
}

.bar.animate:before {
    bottom: 0;
    transform: rotate(-45deg);
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
}

/*nav menu*/
.show {
    right:0%;
    opacity:1;
}

.open-hamburger{
    position: absolute;
    right: 395px;
    @media (min-width: 500px){
        right: 395px;
    }
    @media (min-width: 1025px){
        right:467px;
    }
    @media (max-width: 450px) {
        right: 100%;
        left: 23px;
    }
}
.mobile-nav{
    position:absolute;
    top:-13px;
    left: -15px;
    transition: .2s ease;
    z-index: 3;
    display: block;
    height: 100vh;
    overflow: auto;
    background: white;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
    width:310px;
    @media (min-width: 500px){
        width:413px;
    }
    @media (min-width: 1025px){
        width:480px;
    }
    @media (min-width: 1400px){
        width:42vw;
    }

    &.hide {
        opacity:0;
        left:-100vw;
    }
}

.menu-list{
    li{ border-left: 2px solid white; }
    li.active{
        a{ @extend .bold; }
        border-left-color: $blue;

    }
    margin: 60px 33px 0 20px;

    ul{
        margin-bottom: 20px;
        li{
            padding-left: 10px;
            line-height: 0.5 !important;
            margin-bottom: 5px !important;
        }
    }


}
.mobile-nav ul{
    li{
        color:$black;
        margin-bottom: 0px;
        border-bottom: 1px solid $white;
    }
}

.mobile-nav.show a{
    color:$black;
    transition: ease 0.4s;
    font-size:0.741em;
    line-height: 1.4;

}

.mobile-nav a:hover{
    color: theme-color('yellow');
}

.nav-bar-left{
    .hamburger-menu{ float: none; }
    .right-logo img{ height: 33px; width:auto; }
    .mobile-nav {
        right:auto;
        &.hide {
            left:-100vw;
            right:auto;
        }

    }
    .hamburger-menu.open-hamburger{
        position: absolute;
        margin: 0;

        @media (max-width: 500px){
            left:240px;
        }

        @media (min-width: 500px){
            left:350px;
            right:auto;
        }

        @media (min-width: 1025px){
            left:410px;
            right:auto;
        }

        @media (min-width: 1400px){
            left:calc(42vw - 70px);
            right:auto;

        }


    }
}
