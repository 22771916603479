// Body
$body-bg: #fff;

// Typography
$font-family-sans-serif: 'BentonSans-Book', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #008ED3;
$pink: #BF008C;
$red: #bb0000;
$orange: #F99126;
$yellow: #F0AB00;
$green: #93C938;
$black: #333333;

// Breakpoints
$min           : 360px;
$small         : 480px;
$medium        : 767px;
$large         : 992px;
$max           : 1200px;


.bg-light-grey{ background: #f3f3f3; }
.bg-grey{ background: #d2d2d2; }
.text-orange{ color: $orange; }
.text-yellow{ color: $yellow; }
.text-blue{ color: $blue; }

.btn{ border-radius: 0!important; }

@font-face {
    font-family: 'Pizza';
    src: url("/fonts/pizza/PizzaismyFAVORITE.ttf") format("truetype")
}
@font-face {
    font-family: 'BentonSans-Book';
    src: url("/fonts/benton-sans/BentonSans-Book.ttf") format("truetype")
}
@font-face {
    font-family: 'BentonSans-Regular';
    src: url("/fonts/benton-sans/benton-sans-regular.woff") format("woff"), url("/fonts/benton-sans/benton-sans-regular.ttf") format("truetype");
    //url("/fonts/benton-sans/benton-sans-regular.eot") format("embedded-opentype")
}
@font-face {
    font-family: 'BentonSans-Medium';
    src: url("/fonts/benton-sans/benton-sans-medium.eot") format("embedded-opentype"), url("/fonts/benton-sans/benton-sans-medium.woff") format("woff"), url("/fonts/benton-sans/benton-sans-medium.ttf") format("truetype")
}
@font-face {
    font-family: 'BentonSans-Bold';
    src: url("/fonts/benton-sans/benton-sans-bold.eot") format("embedded-opentype"), url("/fonts/benton-sans/benton-sans-bold.woff") format("woff"), url("/fonts/benton-sans/benton-sans-bold.ttf") format("truetype")
}

@font-face {
    font-family: 'BentonSans-Light';
    src:url("/fonts/benton-sans/benton-sans-light.woff") format("woff"), url("/fonts/benton-sans/benton-sans-light.ttf") format("truetype")
}

html,body{
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

.pizza{ font-family: Pizza,'PizzaismyFAVOURITE'; font-weight: normal; }
.bold{ font-family: "BentonSans-Bold"; font-weight: normal; }
.light{ font-family: "BentonSans-Light"; font-weight: normal; }
.medium{ font-family: "BentonSans-Medium"; font-weight: normal;}
.regular{ font-family: "BentonSans-Regular"; font-weight: normal;}
.book{ font-family: "BentonSans-Book"; font-weight: normal;}
