// Rotated & Flipped Icons
// -------------------------

.#{$sap-css-prefix}-rotate-90  { @include sap-icon-rotate(90deg, 1);  }
.#{$sap-css-prefix}-rotate-180 { @include sap-icon-rotate(180deg, 2); }
.#{$sap-css-prefix}-rotate-270 { @include sap-icon-rotate(270deg, 3); }

.#{$sap-css-prefix}-flip-horizontal { @include sap-icon-flip(-1, 1, 0); }
.#{$sap-css-prefix}-flip-vertical   { @include sap-icon-flip(1, -1, 2); }
.#{$sap-css-prefix}-flip-horizontal.#{$sap-css-prefix}-flip-vertical { @include sap-icon-flip(-1, -1, 2); }

// Hook for IE8-9
// -------------------------

:root {
  .#{$sap-css-prefix}-rotate-90,
  .#{$sap-css-prefix}-rotate-180,
  .#{$sap-css-prefix}-rotate-270,
  .#{$sap-css-prefix}-flip-horizontal,
  .#{$sap-css-prefix}-flip-vertical {
    filter: none;
  }
}
