.home{
    .panel{ background: white; box-shadow: 0px 0px 20px rgba(0,0,0,0.3)}
     .h1{
         font-size: inherit;
         p{ font-size: 3.185em; line-height: 1.05; }
     }
     h2{ font-size: 1.7777em; }
    .next-chapter{ position: absolute; top: 50%; right: 0; transform: translateY(-50%);
        line-height: 1.25;
        padding-top: 13px;
        padding-bottom: 13px;
        font-size: 1.1em;
    }

}

@media (max-width:850px) {
    .home {
        .h1 {
            p {
                font-size: 2em;
                br{ display: none; }
            }
        }

        h2 {
            font-size: 1.2em;
        }
    }
}
@media (max-width:550px) {
    .home {
        .next-chapter{ top: 80%; }
        .h1 {
            p {
                font-size: 1.7em;
                br{ display: none; }
            }
        }

        h2 {
            font-size: 1em;
        }
    }
}
