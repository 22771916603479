@import 'variables';
@import 'mixins';
@import 'core';
@import 'larger';
@import 'fixed-width';
@import 'list';
@import 'bordered-pulled';
@import 'animated';
@import 'rotated-flipped';
@import 'stacked';
@import 'icons';
@import 'screen-reader';

@font-face {
  font-family: 'SAP-Icons';
  font-style: normal;
  font-weight: 400;
  src: url('#{$sap-font-path}/SAP-icons.woff2') format('woff2'),
  url('#{$sap-font-path}/SAP-icons.woff') format('woff'),
  url('#{$sap-font-path}/SAP-icons.ttf') format('truetype');
}

.sap {
  font-family: 'SAP-Icons';
  font-weight: 400;
}
