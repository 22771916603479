/* SAP uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */


.#{$sap-css-prefix}-accidental-leave:before { content: sap-content($sap-var-accidental-leave); }
.#{$sap-css-prefix}-account:before { content: sap-content($sap-var-account); }
.#{$sap-css-prefix}-wrench:before { content: sap-content($sap-var-wrench); }
.#{$sap-css-prefix}-windows-doors:before { content: sap-content($sap-var-windows-doors); }
.#{$sap-css-prefix}-washing-machine:before { content: sap-content($sap-var-washing-machine); }
.#{$sap-css-prefix}-visits:before { content: sap-content($sap-var-visits); }
.#{$sap-css-prefix}-video:before { content: sap-content($sap-var-video); }
.#{$sap-css-prefix}-travel-expense:before { content: sap-content($sap-var-travel-expense); }
.#{$sap-css-prefix}-temperature:before { content: sap-content($sap-var-temperature); }
.#{$sap-css-prefix}-task:before { content: sap-content($sap-var-task); }
.#{$sap-css-prefix}-synchronize:before { content: sap-content($sap-var-synchronize); }
.#{$sap-css-prefix}-survey:before { content: sap-content($sap-var-survey); }
.#{$sap-css-prefix}-settings:before { content: sap-content($sap-var-settings); }
.#{$sap-css-prefix}-search:before { content: sap-content($sap-var-search); }
.#{$sap-css-prefix}-sales-document:before { content: sap-content($sap-var-sales-document); }
.#{$sap-css-prefix}-retail-store:before { content: sap-content($sap-var-retail-store); }
.#{$sap-css-prefix}-refresh:before { content: sap-content($sap-var-refresh); }
.#{$sap-css-prefix}-product:before { content: sap-content($sap-var-product); }
.#{$sap-css-prefix}-present:before { content: sap-content($sap-var-present); }
.#{$sap-css-prefix}-ppt-attachment:before { content: sap-content($sap-var-ppt-attachment); }
.#{$sap-css-prefix}-pool:before { content: sap-content($sap-var-pool); }
.#{$sap-css-prefix}-pie-chart:before { content: sap-content($sap-var-pie-chart); }
.#{$sap-css-prefix}-picture:before { content: sap-content($sap-var-picture); }
.#{$sap-css-prefix}-photo-voltaic:before { content: sap-content($sap-var-photo-voltaic); }
.#{$sap-css-prefix}-phone:before { content: sap-content($sap-var-phone); }
.#{$sap-css-prefix}-pending:before { content: sap-content($sap-var-pending); }
.#{$sap-css-prefix}-pdf-attachment:before { content: sap-content($sap-var-pdf-attachment); }
.#{$sap-css-prefix}-past:before { content: sap-content($sap-var-past); }
.#{$sap-css-prefix}-outgoing-call:before { content: sap-content($sap-var-outgoing-call); }
.#{$sap-css-prefix}-opportunity:before { content: sap-content($sap-var-opportunity); }
.#{$sap-css-prefix}-opportunities:before { content: sap-content($sap-var-opportunities); }
.#{$sap-css-prefix}-notes:before { content: sap-content($sap-var-notes); }
.#{$sap-css-prefix}-money-bills:before { content: sap-content($sap-var-money-bills); }
.#{$sap-css-prefix}-map:before { content: sap-content($sap-var-map); }
.#{$sap-css-prefix}-log:before { content: sap-content($sap-var-log); }
.#{$sap-css-prefix}-line-charts:before { content: sap-content($sap-var-line-charts); }
.#{$sap-css-prefix}-lightbulb:before { content: sap-content($sap-var-lightbulb); }
.#{$sap-css-prefix}-leads:before { content: sap-content($sap-var-leads); }
.#{$sap-css-prefix}-lead:before { content: sap-content($sap-var-lead); }
.#{$sap-css-prefix}-laptop:before { content: sap-content($sap-var-laptop); }
.#{$sap-css-prefix}-kpi-managing:before { content: sap-content($sap-var-kpi-managing); }
.#{$sap-css-prefix}-kpi-corporate-performance:before { content: sap-content($sap-var-kpi-corporate-performance); }
.#{$sap-css-prefix}-incoming-call:before { content: sap-content($sap-var-incoming-call); }
.#{$sap-css-prefix}-inbox:before { content: sap-content($sap-var-inbox); }
.#{$sap-css-prefix}-horizontal-bar-chart:before { content: sap-content($sap-var-horizontal-bar-chart); }
.#{$sap-css-prefix}-history:before { content: sap-content($sap-var-history); }
.#{$sap-css-prefix}-heating-cooling:before { content: sap-content($sap-var-heating-cooling); }
.#{$sap-css-prefix}-gantt-bars:before { content: sap-content($sap-var-gantt-bars); }
.#{$sap-css-prefix}-future:before { content: sap-content($sap-var-future); }
.#{$sap-css-prefix}-fridge:before { content: sap-content($sap-var-fridge); }
.#{$sap-css-prefix}-fallback:before { content: sap-content($sap-var-fallback); }
.#{$sap-css-prefix}-expense-report:before { content: sap-content($sap-var-expense-report); }
.#{$sap-css-prefix}-excel-attachment:before { content: sap-content($sap-var-excel-attachment); }
.#{$sap-css-prefix}-energy-saving-lightbulb:before { content: sap-content($sap-var-energy-saving-lightbulb); }
.#{$sap-css-prefix}-employee:before { content: sap-content($sap-var-employee); }
.#{$sap-css-prefix}-email:before { content: sap-content($sap-var-email); }
.#{$sap-css-prefix}-edit:before { content: sap-content($sap-var-edit); }
.#{$sap-css-prefix}-duplicate:before { content: sap-content($sap-var-duplicate); }
.#{$sap-css-prefix}-download:before { content: sap-content($sap-var-download); }
.#{$sap-css-prefix}-doc-attachment:before { content: sap-content($sap-var-doc-attachment); }
.#{$sap-css-prefix}-dishwasher:before { content: sap-content($sap-var-dishwasher); }
.#{$sap-css-prefix}-delete:before { content: sap-content($sap-var-delete); }
.#{$sap-css-prefix}-decline:before { content: sap-content($sap-var-decline); }
.#{$sap-css-prefix}-complete:before { content: sap-content($sap-var-complete); }
.#{$sap-css-prefix}-competitor:before { content: sap-content($sap-var-competitor); }
.#{$sap-css-prefix}-collections-management:before { content: sap-content($sap-var-collections-management); }
.#{$sap-css-prefix}-chalkboard:before { content: sap-content($sap-var-chalkboard); }
.#{$sap-css-prefix}-cart:before { content: sap-content($sap-var-cart); }
.#{$sap-css-prefix}-card:before { content: sap-content($sap-var-card); }
.#{$sap-css-prefix}-camera:before { content: sap-content($sap-var-camera); }
.#{$sap-css-prefix}-calendar:before { content: sap-content($sap-var-calendar); }
.#{$sap-css-prefix}-begin:before { content: sap-content($sap-var-begin); }
.#{$sap-css-prefix}-basket:before { content: sap-content($sap-var-basket); }
.#{$sap-css-prefix}-bar-chart:before { content: sap-content($sap-var-bar-chart); }
.#{$sap-css-prefix}-attachment:before { content: sap-content($sap-var-attachment); }
.#{$sap-css-prefix}-arrow-top:before { content: sap-content($sap-var-arrow-top); }
.#{$sap-css-prefix}-arrow-right:before { content: sap-content($sap-var-arrow-right); }
.#{$sap-css-prefix}-arrow-left:before { content: sap-content($sap-var-arrow-left); }
.#{$sap-css-prefix}-arrow-bottom:before { content: sap-content($sap-var-arrow-bottom); }
.#{$sap-css-prefix}-approvals:before { content: sap-content($sap-var-approvals); }
.#{$sap-css-prefix}-appointment:before { content: sap-content($sap-var-appointment); }
.#{$sap-css-prefix}-alphabetical-order:before { content: sap-content($sap-var-alphabetical-order); }
.#{$sap-css-prefix}-along-stacked-chart:before { content: sap-content($sap-var-along-stacked-chart); }
.#{$sap-css-prefix}-alert:before { content: sap-content($sap-var-alert); }
.#{$sap-css-prefix}-addresses:before { content: sap-content($sap-var-addresses); }
.#{$sap-css-prefix}-address-book:before { content: sap-content($sap-var-address-book); }
.#{$sap-css-prefix}-add-filter:before { content: sap-content($sap-var-add-filter); }
.#{$sap-css-prefix}-add-favorite:before { content: sap-content($sap-var-add-favorite); }
.#{$sap-css-prefix}-add:before { content: sap-content($sap-var-add); }
.#{$sap-css-prefix}-activities:before { content: sap-content($sap-var-activities); }
.#{$sap-css-prefix}-action:before { content: sap-content($sap-var-action); }
.#{$sap-css-prefix}-accept:before { content: sap-content($sap-var-accept); }
.#{$sap-css-prefix}-hint:before { content: sap-content($sap-var-hint); }
.#{$sap-css-prefix}-group:before { content: sap-content($sap-var-group); }
.#{$sap-css-prefix}-check-availability:before { content: sap-content($sap-var-check-availability); }
.#{$sap-css-prefix}-weather-proofing:before { content: sap-content($sap-var-weather-proofing); }
.#{$sap-css-prefix}-payment-approval:before { content: sap-content($sap-var-payment-approval); }
.#{$sap-css-prefix}-batch-payments:before { content: sap-content($sap-var-batch-payments); }
.#{$sap-css-prefix}-bed:before { content: sap-content($sap-var-bed); }
.#{$sap-css-prefix}-arobase:before { content: sap-content($sap-var-arobase); }
.#{$sap-css-prefix}-family-care:before { content: sap-content($sap-var-family-care); }
.#{$sap-css-prefix}-favorite:before { content: sap-content($sap-var-favorite); }
.#{$sap-css-prefix}-navigation-right-arrow:before { content: sap-content($sap-var-navigation-right-arrow); }
.#{$sap-css-prefix}-navigation-left-arrow:before { content: sap-content($sap-var-navigation-left-arrow); }
.#{$sap-css-prefix}-e-care:before { content: sap-content($sap-var-e-care); }
.#{$sap-css-prefix}-less:before { content: sap-content($sap-var-less); }
.#{$sap-css-prefix}-lateness:before { content: sap-content($sap-var-lateness); }
.#{$sap-css-prefix}-lab:before { content: sap-content($sap-var-lab); }
.#{$sap-css-prefix}-internet-browser:before { content: sap-content($sap-var-internet-browser); }
.#{$sap-css-prefix}-instance:before { content: sap-content($sap-var-instance); }
.#{$sap-css-prefix}-inspection:before { content: sap-content($sap-var-inspection); }
.#{$sap-css-prefix}-image-viewer:before { content: sap-content($sap-var-image-viewer); }
.#{$sap-css-prefix}-home:before { content: sap-content($sap-var-home); }
.#{$sap-css-prefix}-grid:before { content: sap-content($sap-var-grid); }
.#{$sap-css-prefix}-goalseek:before { content: sap-content($sap-var-goalseek); }
.#{$sap-css-prefix}-general-leave-request:before { content: sap-content($sap-var-general-leave-request); }
.#{$sap-css-prefix}-create-leave-request:before { content: sap-content($sap-var-create-leave-request); }
.#{$sap-css-prefix}-flight:before { content: sap-content($sap-var-flight); }
.#{$sap-css-prefix}-filter:before { content: sap-content($sap-var-filter); }
.#{$sap-css-prefix}-favorite-list:before { content: sap-content($sap-var-favorite-list); }
.#{$sap-css-prefix}-factory:before { content: sap-content($sap-var-factory); }
.#{$sap-css-prefix}-endoscopy:before { content: sap-content($sap-var-endoscopy); }
.#{$sap-css-prefix}-employee-pane:before { content: sap-content($sap-var-employee-pane); }
.#{$sap-css-prefix}-employee-approvals:before { content: sap-content($sap-var-employee-approvals); }
.#{$sap-css-prefix}-email-read:before { content: sap-content($sap-var-email-read); }
.#{$sap-css-prefix}-electrocardiogram:before { content: sap-content($sap-var-electrocardiogram); }
.#{$sap-css-prefix}-documents:before { content: sap-content($sap-var-documents); }
.#{$sap-css-prefix}-decision:before { content: sap-content($sap-var-decision); }
.#{$sap-css-prefix}-database:before { content: sap-content($sap-var-database); }
.#{$sap-css-prefix}-customer-history:before { content: sap-content($sap-var-customer-history); }
.#{$sap-css-prefix}-customer:before { content: sap-content($sap-var-customer); }
.#{$sap-css-prefix}-credit-card:before { content: sap-content($sap-var-credit-card); }
.#{$sap-css-prefix}-create-entry-time:before { content: sap-content($sap-var-create-entry-time); }
.#{$sap-css-prefix}-contacts:before { content: sap-content($sap-var-contacts); }
.#{$sap-css-prefix}-compare:before { content: sap-content($sap-var-compare); }
.#{$sap-css-prefix}-clinical-order:before { content: sap-content($sap-var-clinical-order); }
.#{$sap-css-prefix}-chain-link:before { content: sap-content($sap-var-chain-link); }
.#{$sap-css-prefix}-pull-down:before { content: sap-content($sap-var-pull-down); }
.#{$sap-css-prefix}-cargo-train:before { content: sap-content($sap-var-cargo-train); }
.#{$sap-css-prefix}-car-rental:before { content: sap-content($sap-var-car-rental); }
.#{$sap-css-prefix}-business-card:before { content: sap-content($sap-var-business-card); }
.#{$sap-css-prefix}-bar-code:before { content: sap-content($sap-var-bar-code); }
.#{$sap-css-prefix}-folder-blank:before { content: sap-content($sap-var-folder-blank); }
.#{$sap-css-prefix}-passenger-train:before { content: sap-content($sap-var-passenger-train); }
.#{$sap-css-prefix}-question-mark:before { content: sap-content($sap-var-question-mark); }
.#{$sap-css-prefix}-world:before { content: sap-content($sap-var-world); }
.#{$sap-css-prefix}-iphone:before { content: sap-content($sap-var-iphone); }
.#{$sap-css-prefix}-ipad:before { content: sap-content($sap-var-ipad); }
.#{$sap-css-prefix}-warning:before { content: sap-content($sap-var-warning); }
.#{$sap-css-prefix}-sort:before { content: sap-content($sap-var-sort); }
.#{$sap-css-prefix}-course-book:before { content: sap-content($sap-var-course-book); }
.#{$sap-css-prefix}-course-program:before { content: sap-content($sap-var-course-program); }
.#{$sap-css-prefix}-add-coursebook:before { content: sap-content($sap-var-add-coursebook); }
.#{$sap-css-prefix}-print:before { content: sap-content($sap-var-print); }
.#{$sap-css-prefix}-save:before { content: sap-content($sap-var-save); }
.#{$sap-css-prefix}-play:before { content: sap-content($sap-var-play); }
.#{$sap-css-prefix}-pause:before { content: sap-content($sap-var-pause); }
.#{$sap-css-prefix}-record:before { content: sap-content($sap-var-record); }
.#{$sap-css-prefix}-response:before { content: sap-content($sap-var-response); }
.#{$sap-css-prefix}-pushpin-on:before { content: sap-content($sap-var-pushpin-on); }
.#{$sap-css-prefix}-pushpin-off:before { content: sap-content($sap-var-pushpin-off); }
.#{$sap-css-prefix}-unfavorite:before { content: sap-content($sap-var-unfavorite); }
.#{$sap-css-prefix}-learning-assistant:before { content: sap-content($sap-var-learning-assistant); }
.#{$sap-css-prefix}-timesheet:before { content: sap-content($sap-var-timesheet); }
.#{$sap-css-prefix}-time-entry-request:before { content: sap-content($sap-var-time-entry-request); }
.#{$sap-css-prefix}-list:before { content: sap-content($sap-var-list); }
.#{$sap-css-prefix}-action-settings:before { content: sap-content($sap-var-action-settings); }
.#{$sap-css-prefix}-share:before { content: sap-content($sap-var-share); }
.#{$sap-css-prefix}-feed:before { content: sap-content($sap-var-feed); }
.#{$sap-css-prefix}-role:before { content: sap-content($sap-var-role); }
.#{$sap-css-prefix}-flag:before { content: sap-content($sap-var-flag); }
.#{$sap-css-prefix}-post:before { content: sap-content($sap-var-post); }
.#{$sap-css-prefix}-inspect:before { content: sap-content($sap-var-inspect); }
.#{$sap-css-prefix}-inspect-down:before { content: sap-content($sap-var-inspect-down); }
.#{$sap-css-prefix}-appointment-2:before { content: sap-content($sap-var-appointment-2); }
.#{$sap-css-prefix}-target-group:before { content: sap-content($sap-var-target-group); }
.#{$sap-css-prefix}-marketing-campaign:before { content: sap-content($sap-var-marketing-campaign); }
.#{$sap-css-prefix}-message-error:before { content: sap-content($sap-var-message-error); }
.#{$sap-css-prefix}-comment:before { content: sap-content($sap-var-comment); }
.#{$sap-css-prefix}-shipping-status:before { content: sap-content($sap-var-shipping-status); }
.#{$sap-css-prefix}-collaborate:before { content: sap-content($sap-var-collaborate); }
.#{$sap-css-prefix}-shortcut:before { content: sap-content($sap-var-shortcut); }
.#{$sap-css-prefix}-lead-outdated:before { content: sap-content($sap-var-lead-outdated); }
.#{$sap-css-prefix}-tools-opportunity:before { content: sap-content($sap-var-tools-opportunity); }
.#{$sap-css-prefix}-permission:before { content: sap-content($sap-var-permission); }
.#{$sap-css-prefix}-supplier:before { content: sap-content($sap-var-supplier); }
.#{$sap-css-prefix}-table-view:before { content: sap-content($sap-var-table-view); }
.#{$sap-css-prefix}-table-chart:before { content: sap-content($sap-var-table-chart); }
.#{$sap-css-prefix}-switch-views:before { content: sap-content($sap-var-switch-views); }
.#{$sap-css-prefix}-e-learning:before { content: sap-content($sap-var-e-learning); }
.#{$sap-css-prefix}-manager:before { content: sap-content($sap-var-manager); }
.#{$sap-css-prefix}-switch-classes:before { content: sap-content($sap-var-switch-classes); }
.#{$sap-css-prefix}-simple-payment:before { content: sap-content($sap-var-simple-payment); }
.#{$sap-css-prefix}-signature:before { content: sap-content($sap-var-signature); }
.#{$sap-css-prefix}-sales-order-item:before { content: sap-content($sap-var-sales-order-item); }
.#{$sap-css-prefix}-sales-order:before { content: sap-content($sap-var-sales-order); }
.#{$sap-css-prefix}-request:before { content: sap-content($sap-var-request); }
.#{$sap-css-prefix}-receipt:before { content: sap-content($sap-var-receipt); }
.#{$sap-css-prefix}-puzzle:before { content: sap-content($sap-var-puzzle); }
.#{$sap-css-prefix}-process:before { content: sap-content($sap-var-process); }
.#{$sap-css-prefix}-private:before { content: sap-content($sap-var-private); }
.#{$sap-css-prefix}-popup-window:before { content: sap-content($sap-var-popup-window); }
.#{$sap-css-prefix}-person-placeholder:before { content: sap-content($sap-var-person-placeholder); }
.#{$sap-css-prefix}-per-diem:before { content: sap-content($sap-var-per-diem); }
.#{$sap-css-prefix}-paper-plane:before { content: sap-content($sap-var-paper-plane); }
.#{$sap-css-prefix}-paid-leave:before { content: sap-content($sap-var-paid-leave); }
.#{$sap-css-prefix}-pdf-reader:before { content: sap-content($sap-var-pdf-reader); }
.#{$sap-css-prefix}-overview-chart:before { content: sap-content($sap-var-overview-chart); }
.#{$sap-css-prefix}-overlay:before { content: sap-content($sap-var-overlay); }
.#{$sap-css-prefix}-org-chart:before { content: sap-content($sap-var-org-chart); }
.#{$sap-css-prefix}-number-sign:before { content: sap-content($sap-var-number-sign); }
.#{$sap-css-prefix}-notification-2:before { content: sap-content($sap-var-notification-2); }
.#{$sap-css-prefix}-my-sales-order:before { content: sap-content($sap-var-my-sales-order); }
.#{$sap-css-prefix}-meal:before { content: sap-content($sap-var-meal); }
.#{$sap-css-prefix}-loan:before { content: sap-content($sap-var-loan); }
.#{$sap-css-prefix}-order-status:before { content: sap-content($sap-var-order-status); }
.#{$sap-css-prefix}-customer-order-entry:before { content: sap-content($sap-var-customer-order-entry); }
.#{$sap-css-prefix}-performance:before { content: sap-content($sap-var-performance); }
.#{$sap-css-prefix}-menu:before { content: sap-content($sap-var-menu); }
.#{$sap-css-prefix}-employee-lookup:before { content: sap-content($sap-var-employee-lookup); }
.#{$sap-css-prefix}-education:before { content: sap-content($sap-var-education); }
.#{$sap-css-prefix}-customer-briefing:before { content: sap-content($sap-var-customer-briefing); }
.#{$sap-css-prefix}-customer-and-contacts:before { content: sap-content($sap-var-customer-and-contacts); }
.#{$sap-css-prefix}-my-view:before { content: sap-content($sap-var-my-view); }
.#{$sap-css-prefix}-accelerated:before { content: sap-content($sap-var-accelerated); }
.#{$sap-css-prefix}-to-be-reviewed:before { content: sap-content($sap-var-to-be-reviewed); }
.#{$sap-css-prefix}-warning-2:before { content: sap-content($sap-var-warning-2); }
.#{$sap-css-prefix}-feeder-arrow:before { content: sap-content($sap-var-feeder-arrow); }
.#{$sap-css-prefix}-quality-issue:before { content: sap-content($sap-var-quality-issue); }
.#{$sap-css-prefix}-workflow-tasks:before { content: sap-content($sap-var-workflow-tasks); }
.#{$sap-css-prefix}-create:before { content: sap-content($sap-var-create); }
.#{$sap-css-prefix}-home-share:before { content: sap-content($sap-var-home-share); }
.#{$sap-css-prefix}-globe:before { content: sap-content($sap-var-globe); }
.#{$sap-css-prefix}-tags:before { content: sap-content($sap-var-tags); }
.#{$sap-css-prefix}-work-history:before { content: sap-content($sap-var-work-history); }
.#{$sap-css-prefix}-x-ray:before { content: sap-content($sap-var-x-ray); }
.#{$sap-css-prefix}-wounds-doc:before { content: sap-content($sap-var-wounds-doc); }
.#{$sap-css-prefix}-web-cam:before { content: sap-content($sap-var-web-cam); }
.#{$sap-css-prefix}-waiver:before { content: sap-content($sap-var-waiver); }
.#{$sap-css-prefix}-vertical-bar-chart:before { content: sap-content($sap-var-vertical-bar-chart); }
.#{$sap-css-prefix}-upstacked-chart:before { content: sap-content($sap-var-upstacked-chart); }
.#{$sap-css-prefix}-trip-report:before { content: sap-content($sap-var-trip-report); }
.#{$sap-css-prefix}-microphone:before { content: sap-content($sap-var-microphone); }
.#{$sap-css-prefix}-unpaid-leave:before { content: sap-content($sap-var-unpaid-leave); }
.#{$sap-css-prefix}-tree:before { content: sap-content($sap-var-tree); }
.#{$sap-css-prefix}-toaster-up:before { content: sap-content($sap-var-toaster-up); }
.#{$sap-css-prefix}-toaster-top:before { content: sap-content($sap-var-toaster-top); }
.#{$sap-css-prefix}-toaster-down:before { content: sap-content($sap-var-toaster-down); }
.#{$sap-css-prefix}-time-account:before { content: sap-content($sap-var-time-account); }
.#{$sap-css-prefix}-theater:before { content: sap-content($sap-var-theater); }
.#{$sap-css-prefix}-taxi:before { content: sap-content($sap-var-taxi); }
.#{$sap-css-prefix}-subway-train:before { content: sap-content($sap-var-subway-train); }
.#{$sap-css-prefix}-study-leave:before { content: sap-content($sap-var-study-leave); }
.#{$sap-css-prefix}-stethoscope:before { content: sap-content($sap-var-stethoscope); }
.#{$sap-css-prefix}-step:before { content: sap-content($sap-var-step); }
.#{$sap-css-prefix}-sonography:before { content: sap-content($sap-var-sonography); }
.#{$sap-css-prefix}-soccor:before { content: sap-content($sap-var-soccor); }
.#{$sap-css-prefix}-physical-activity:before { content: sap-content($sap-var-physical-activity); }
.#{$sap-css-prefix}-pharmacy:before { content: sap-content($sap-var-pharmacy); }
.#{$sap-css-prefix}-official-service:before { content: sap-content($sap-var-official-service); }
.#{$sap-css-prefix}-offsite-work:before { content: sap-content($sap-var-offsite-work); }
.#{$sap-css-prefix}-nutrition-activity:before { content: sap-content($sap-var-nutrition-activity); }
.#{$sap-css-prefix}-newspaper:before { content: sap-content($sap-var-newspaper); }
.#{$sap-css-prefix}-monitor-payments:before { content: sap-content($sap-var-monitor-payments); }
.#{$sap-css-prefix}-map-2:before { content: sap-content($sap-var-map-2); }
.#{$sap-css-prefix}-machine:before { content: sap-content($sap-var-machine); }
.#{$sap-css-prefix}-mri-scan:before { content: sap-content($sap-var-mri-scan); }
.#{$sap-css-prefix}-end-user-experience:before { content: sap-content($sap-var-end-user-experience); }
.#{$sap-css-prefix}-unwired:before { content: sap-content($sap-var-unwired); }
.#{$sap-css-prefix}-customer-financial-fact-sheet:before { content: sap-content($sap-var-customer-financial-fact-sheet); }
.#{$sap-css-prefix}-retail-store-manager:before { content: sap-content($sap-var-retail-store-manager); }
.#{$sap-css-prefix}-Netweaver-business-client:before { content: sap-content($sap-var-Netweaver-business-client); }
.#{$sap-css-prefix}-electronic-medical-record:before { content: sap-content($sap-var-electronic-medical-record); }
.#{$sap-css-prefix}-eam-work-order:before { content: sap-content($sap-var-eam-work-order); }
.#{$sap-css-prefix}-customer-view:before { content: sap-content($sap-var-customer-view); }
.#{$sap-css-prefix}-crm-service-manager:before { content: sap-content($sap-var-crm-service-manager); }
.#{$sap-css-prefix}-crm-sales:before { content: sap-content($sap-var-crm-sales); }
.#{$sap-css-prefix}-widgets:before { content: sap-content($sap-var-widgets); }
.#{$sap-css-prefix}-commission-check:before { content: sap-content($sap-var-commission-check); }
.#{$sap-css-prefix}-collections-insight:before { content: sap-content($sap-var-collections-insight); }
.#{$sap-css-prefix}-clinical-tast-tracker:before { content: sap-content($sap-var-clinical-tast-tracker); }
.#{$sap-css-prefix}-citizen-connect:before { content: sap-content($sap-var-citizen-connect); }
.#{$sap-css-prefix}-cart-approval:before { content: sap-content($sap-var-cart-approval); }
.#{$sap-css-prefix}-capital-projects:before { content: sap-content($sap-var-capital-projects); }
.#{$sap-css-prefix}-bo-strategy-management:before { content: sap-content($sap-var-bo-strategy-management); }
.#{$sap-css-prefix}-business-objects-mobile:before { content: sap-content($sap-var-business-objects-mobile); }
.#{$sap-css-prefix}-business-objects-explorer:before { content: sap-content($sap-var-business-objects-explorer); }
.#{$sap-css-prefix}-business-objects-experience:before { content: sap-content($sap-var-business-objects-experience); }
.#{$sap-css-prefix}-bbyd-dashboard:before { content: sap-content($sap-var-bbyd-dashboard); }
.#{$sap-css-prefix}-bbyd-active-sales:before { content: sap-content($sap-var-bbyd-active-sales); }
.#{$sap-css-prefix}-business-by-design:before { content: sap-content($sap-var-business-by-design); }
.#{$sap-css-prefix}-business-one:before { content: sap-content($sap-var-business-one); }
.#{$sap-css-prefix}-sap-box:before { content: sap-content($sap-var-sap-box); }
.#{$sap-css-prefix}-manager-insight:before { content: sap-content($sap-var-manager-insight); }
.#{$sap-css-prefix}-accounting-document-verification:before { content: sap-content($sap-var-accounting-document-verification); }
.#{$sap-css-prefix}-hr-approval:before { content: sap-content($sap-var-hr-approval); }
.#{$sap-css-prefix}-idea-wall:before { content: sap-content($sap-var-idea-wall); }
.#{$sap-css-prefix}-Chart-Tree-Map:before { content: sap-content($sap-var-Chart-Tree-Map); }
.#{$sap-css-prefix}-cart-5:before { content: sap-content($sap-var-cart-5); }
.#{$sap-css-prefix}-cart-4:before { content: sap-content($sap-var-cart-4); }
.#{$sap-css-prefix}-wallet:before { content: sap-content($sap-var-wallet); }
.#{$sap-css-prefix}-vehicle-repair:before { content: sap-content($sap-var-vehicle-repair); }
.#{$sap-css-prefix}-upload:before { content: sap-content($sap-var-upload); }
.#{$sap-css-prefix}-unlocked:before { content: sap-content($sap-var-unlocked); }
.#{$sap-css-prefix}-umbrella:before { content: sap-content($sap-var-umbrella); }
.#{$sap-css-prefix}-travel-request:before { content: sap-content($sap-var-travel-request); }
.#{$sap-css-prefix}-travel-expense-report:before { content: sap-content($sap-var-travel-expense-report); }
.#{$sap-css-prefix}-travel-itinerary:before { content: sap-content($sap-var-travel-itinerary); }
.#{$sap-css-prefix}-time-overtime:before { content: sap-content($sap-var-time-overtime); }
.#{$sap-css-prefix}-thing-type:before { content: sap-content($sap-var-thing-type); }
.#{$sap-css-prefix}-technical-object:before { content: sap-content($sap-var-technical-object); }
.#{$sap-css-prefix}-tag:before { content: sap-content($sap-var-tag); }
.#{$sap-css-prefix}-syringe:before { content: sap-content($sap-var-syringe); }
.#{$sap-css-prefix}-syntax:before { content: sap-content($sap-var-syntax); }
.#{$sap-css-prefix}-suitcase:before { content: sap-content($sap-var-suitcase); }
.#{$sap-css-prefix}-simulate:before { content: sap-content($sap-var-simulate); }
.#{$sap-css-prefix}-shield:before { content: sap-content($sap-var-shield); }
.#{$sap-css-prefix}-share-2:before { content: sap-content($sap-var-share-2); }
.#{$sap-css-prefix}-sales-quote:before { content: sap-content($sap-var-sales-quote); }
.#{$sap-css-prefix}-repost:before { content: sap-content($sap-var-repost); }
.#{$sap-css-prefix}-provision:before { content: sap-content($sap-var-provision); }
.#{$sap-css-prefix}-projector:before { content: sap-content($sap-var-projector); }
.#{$sap-css-prefix}-add-product:before { content: sap-content($sap-var-add-product); }
.#{$sap-css-prefix}-pipeline-analysis:before { content: sap-content($sap-var-pipeline-analysis); }
.#{$sap-css-prefix}-add-photo:before { content: sap-content($sap-var-add-photo); }
.#{$sap-css-prefix}-palette:before { content: sap-content($sap-var-palette); }
.#{$sap-css-prefix}-nurse:before { content: sap-content($sap-var-nurse); }
.#{$sap-css-prefix}-sales-notification:before { content: sap-content($sap-var-sales-notification); }
.#{$sap-css-prefix}-mileage:before { content: sap-content($sap-var-mileage); }
.#{$sap-css-prefix}-meeting-room:before { content: sap-content($sap-var-meeting-room); }
.#{$sap-css-prefix}-media-forward:before { content: sap-content($sap-var-media-forward); }
.#{$sap-css-prefix}-media-play:before { content: sap-content($sap-var-media-play); }
.#{$sap-css-prefix}-media-pause:before { content: sap-content($sap-var-media-pause); }
.#{$sap-css-prefix}-media-reverse:before { content: sap-content($sap-var-media-reverse); }
.#{$sap-css-prefix}-media-rewind:before { content: sap-content($sap-var-media-rewind); }
.#{$sap-css-prefix}-measurement-document:before { content: sap-content($sap-var-measurement-document); }
.#{$sap-css-prefix}-measuring-point:before { content: sap-content($sap-var-measuring-point); }
.#{$sap-css-prefix}-measure:before { content: sap-content($sap-var-measure); }
.#{$sap-css-prefix}-map-3:before { content: sap-content($sap-var-map-3); }
.#{$sap-css-prefix}-locked:before { content: sap-content($sap-var-locked); }
.#{$sap-css-prefix}-letter:before { content: sap-content($sap-var-letter); }
.#{$sap-css-prefix}-journey-arrive:before { content: sap-content($sap-var-journey-arrive); }
.#{$sap-css-prefix}-journey-change:before { content: sap-content($sap-var-journey-change); }
.#{$sap-css-prefix}-journey-depart:before { content: sap-content($sap-var-journey-depart); }
.#{$sap-css-prefix}-it-system:before { content: sap-content($sap-var-it-system); }
.#{$sap-css-prefix}-it-instance:before { content: sap-content($sap-var-it-instance); }
.#{$sap-css-prefix}-it-host:before { content: sap-content($sap-var-it-host); }
.#{$sap-css-prefix}-iphone-2:before { content: sap-content($sap-var-iphone-2); }
.#{$sap-css-prefix}-ipad-2:before { content: sap-content($sap-var-ipad-2); }
.#{$sap-css-prefix}-inventory:before { content: sap-content($sap-var-inventory); }
.#{$sap-css-prefix}-insurance-house:before { content: sap-content($sap-var-insurance-house); }
.#{$sap-css-prefix}-insurance-life:before { content: sap-content($sap-var-insurance-life); }
.#{$sap-css-prefix}-insurance-car:before { content: sap-content($sap-var-insurance-car); }
.#{$sap-css-prefix}-initiative:before { content: sap-content($sap-var-initiative); }
.#{$sap-css-prefix}-incident:before { content: sap-content($sap-var-incident); }
.#{$sap-css-prefix}-group-2:before { content: sap-content($sap-var-group-2); }
.#{$sap-css-prefix}-goal:before { content: sap-content($sap-var-goal); }
.#{$sap-css-prefix}-functional-location:before { content: sap-content($sap-var-functional-location); }
.#{$sap-css-prefix}-full-screen:before { content: sap-content($sap-var-full-screen); }
.#{$sap-css-prefix}-form:before { content: sap-content($sap-var-form); }
.#{$sap-css-prefix}-fob-watch:before { content: sap-content($sap-var-fob-watch); }
.#{$sap-css-prefix}-blank-tag:before { content: sap-content($sap-var-blank-tag); }
.#{$sap-css-prefix}-family-protection:before { content: sap-content($sap-var-family-protection); }
.#{$sap-css-prefix}-folder:before { content: sap-content($sap-var-folder); }
.#{$sap-css-prefix}-fax-machine:before { content: sap-content($sap-var-fax-machine); }
.#{$sap-css-prefix}-example:before { content: sap-content($sap-var-example); }
.#{$sap-css-prefix}-eraser:before { content: sap-content($sap-var-eraser); }
.#{$sap-css-prefix}-employee-rejections:before { content: sap-content($sap-var-employee-rejections); }
.#{$sap-css-prefix}-drop-down-list:before { content: sap-content($sap-var-drop-down-list); }
.#{$sap-css-prefix}-draw-rectangle:before { content: sap-content($sap-var-draw-rectangle); }
.#{$sap-css-prefix}-document:before { content: sap-content($sap-var-document); }
.#{$sap-css-prefix}-doctor:before { content: sap-content($sap-var-doctor); }
.#{$sap-css-prefix}-discussion-2:before { content: sap-content($sap-var-discussion-2); }
.#{$sap-css-prefix}-discussion:before { content: sap-content($sap-var-discussion); }
.#{$sap-css-prefix}-dimension:before { content: sap-content($sap-var-dimension); }
.#{$sap-css-prefix}-customer-and-supplier:before { content: sap-content($sap-var-customer-and-supplier); }
.#{$sap-css-prefix}-crop:before { content: sap-content($sap-var-crop); }
.#{$sap-css-prefix}-add-contact:before { content: sap-content($sap-var-add-contact); }
.#{$sap-css-prefix}-compare-2:before { content: sap-content($sap-var-compare-2); }
.#{$sap-css-prefix}-color-fill:before { content: sap-content($sap-var-color-fill); }
.#{$sap-css-prefix}-collision:before { content: sap-content($sap-var-collision); }
.#{$sap-css-prefix}-curriculum:before { content: sap-content($sap-var-curriculum); }
.#{$sap-css-prefix}-chart-axis:before { content: sap-content($sap-var-chart-axis); }
.#{$sap-css-prefix}-full-stacked-chart:before { content: sap-content($sap-var-full-stacked-chart); }
.#{$sap-css-prefix}-full-stacked-column-chart:before { content: sap-content($sap-var-full-stacked-column-chart); }
.#{$sap-css-prefix}-vertical-bar-chart-2:before { content: sap-content($sap-var-vertical-bar-chart-2); }
.#{$sap-css-prefix}-horizontal-bar-chart-2:before { content: sap-content($sap-var-horizontal-bar-chart-2); }
.#{$sap-css-prefix}-horizontal-stacked-chart:before { content: sap-content($sap-var-horizontal-stacked-chart); }
.#{$sap-css-prefix}-vertical-stacked-chart:before { content: sap-content($sap-var-vertical-stacked-chart); }
.#{$sap-css-prefix}-choropleth-chart:before { content: sap-content($sap-var-choropleth-chart); }
.#{$sap-css-prefix}-geographic-bubble-chart:before { content: sap-content($sap-var-geographic-bubble-chart); }
.#{$sap-css-prefix}-multiple-radar-chart:before { content: sap-content($sap-var-multiple-radar-chart); }
.#{$sap-css-prefix}-radar-chart:before { content: sap-content($sap-var-radar-chart); }
.#{$sap-css-prefix}-crossed-line-chart:before { content: sap-content($sap-var-crossed-line-chart); }
.#{$sap-css-prefix}-multiple-line-chart:before { content: sap-content($sap-var-multiple-line-chart); }
.#{$sap-css-prefix}-multiple-bar-chart:before { content: sap-content($sap-var-multiple-bar-chart); }
.#{$sap-css-prefix}-line-chart:before { content: sap-content($sap-var-line-chart); }
.#{$sap-css-prefix}-line-chart-dual-axis:before { content: sap-content($sap-var-line-chart-dual-axis); }
.#{$sap-css-prefix}-bubble-chart:before { content: sap-content($sap-var-bubble-chart); }
.#{$sap-css-prefix}-scatter-chart:before { content: sap-content($sap-var-scatter-chart); }
.#{$sap-css-prefix}-multiple-pie-chart:before { content: sap-content($sap-var-multiple-pie-chart); }
.#{$sap-css-prefix}-column-chart-dual-axis:before { content: sap-content($sap-var-column-chart-dual-axis); }
.#{$sap-css-prefix}-tag-cloud-chart:before { content: sap-content($sap-var-tag-cloud-chart); }
.#{$sap-css-prefix}-area-chart:before { content: sap-content($sap-var-area-chart); }
.#{$sap-css-prefix}-cause:before { content: sap-content($sap-var-cause); }
.#{$sap-css-prefix}-cart-3:before { content: sap-content($sap-var-cart-3); }
.#{$sap-css-prefix}-cart-2:before { content: sap-content($sap-var-cart-2); }
.#{$sap-css-prefix}-bus-public-transport:before { content: sap-content($sap-var-bus-public-transport); }
.#{$sap-css-prefix}-burglary:before { content: sap-content($sap-var-burglary); }
.#{$sap-css-prefix}-building:before { content: sap-content($sap-var-building); }
.#{$sap-css-prefix}-border:before { content: sap-content($sap-var-border); }
.#{$sap-css-prefix}-bookmark:before { content: sap-content($sap-var-bookmark); }
.#{$sap-css-prefix}-badge:before { content: sap-content($sap-var-badge); }
.#{$sap-css-prefix}-attachment-audio:before { content: sap-content($sap-var-attachment-audio); }
.#{$sap-css-prefix}-attachment-video:before { content: sap-content($sap-var-attachment-video); }
.#{$sap-css-prefix}-attachment-html:before { content: sap-content($sap-var-attachment-html); }
.#{$sap-css-prefix}-attachment-photo:before { content: sap-content($sap-var-attachment-photo); }
.#{$sap-css-prefix}-attachment-e-pub:before { content: sap-content($sap-var-attachment-e-pub); }
.#{$sap-css-prefix}-attachment-zip-file:before { content: sap-content($sap-var-attachment-zip-file); }
.#{$sap-css-prefix}-attachment-text-file:before { content: sap-content($sap-var-attachment-text-file); }
.#{$sap-css-prefix}-add-equipment:before { content: sap-content($sap-var-add-equipment); }
.#{$sap-css-prefix}-add-activity:before { content: sap-content($sap-var-add-activity); }
.#{$sap-css-prefix}-activity-individual:before { content: sap-content($sap-var-activity-individual); }
.#{$sap-css-prefix}-activity-2:before { content: sap-content($sap-var-activity-2); }
.#{$sap-css-prefix}-add-activity-2:before { content: sap-content($sap-var-add-activity-2); }
.#{$sap-css-prefix}-activity-items:before { content: sap-content($sap-var-activity-items); }
.#{$sap-css-prefix}-activity-assigned-to-goal:before { content: sap-content($sap-var-activity-assigned-to-goal); }
.#{$sap-css-prefix}-status-completed:before { content: sap-content($sap-var-status-completed); }
.#{$sap-css-prefix}-status-error:before { content: sap-content($sap-var-status-error); }
.#{$sap-css-prefix}-status-inactive:before { content: sap-content($sap-var-status-inactive); }
.#{$sap-css-prefix}-status-critical:before { content: sap-content($sap-var-status-critical); }
.#{$sap-css-prefix}-blank-tag-2:before { content: sap-content($sap-var-blank-tag-2); }
.#{$sap-css-prefix}-cart-full:before { content: sap-content($sap-var-cart-full); }
.#{$sap-css-prefix}-locate-me:before { content: sap-content($sap-var-locate-me); }
.#{$sap-css-prefix}-paging:before { content: sap-content($sap-var-paging); }
.#{$sap-css-prefix}-company-view:before { content: sap-content($sap-var-company-view); }
.#{$sap-css-prefix}-document-text:before { content: sap-content($sap-var-document-text); }
.#{$sap-css-prefix}-explorer:before { content: sap-content($sap-var-explorer); }
.#{$sap-css-prefix}-personnel-view:before { content: sap-content($sap-var-personnel-view); }
.#{$sap-css-prefix}-sorting-ranking:before { content: sap-content($sap-var-sorting-ranking); }
.#{$sap-css-prefix}-drill-down:before { content: sap-content($sap-var-drill-down); }
.#{$sap-css-prefix}-drill-up:before { content: sap-content($sap-var-drill-up); }
.#{$sap-css-prefix}-vds-file:before { content: sap-content($sap-var-vds-file); }
.#{$sap-css-prefix}-sap-logo-shape:before { content: sap-content($sap-var-sap-logo-shape); }
.#{$sap-css-prefix}-folder-full:before { content: sap-content($sap-var-folder-full); }
.#{$sap-css-prefix}-system-exit:before { content: sap-content($sap-var-system-exit); }
.#{$sap-css-prefix}-system-exit-2:before { content: sap-content($sap-var-system-exit-2); }
.#{$sap-css-prefix}-close-command-field:before { content: sap-content($sap-var-close-command-field); }
.#{$sap-css-prefix}-open-command-field:before { content: sap-content($sap-var-open-command-field); }
.#{$sap-css-prefix}-sys-enter-2:before { content: sap-content($sap-var-sys-enter-2); }
.#{$sap-css-prefix}-sys-enter:before { content: sap-content($sap-var-sys-enter); }
.#{$sap-css-prefix}-sys-help-2:before { content: sap-content($sap-var-sys-help-2); }
.#{$sap-css-prefix}-sys-help:before { content: sap-content($sap-var-sys-help); }
.#{$sap-css-prefix}-sys-back:before { content: sap-content($sap-var-sys-back); }
.#{$sap-css-prefix}-sys-back-2:before { content: sap-content($sap-var-sys-back-2); }
.#{$sap-css-prefix}-sys-cancel:before { content: sap-content($sap-var-sys-cancel); }
.#{$sap-css-prefix}-sys-cancel-2:before { content: sap-content($sap-var-sys-cancel-2); }
.#{$sap-css-prefix}-open-folder:before { content: sap-content($sap-var-open-folder); }
.#{$sap-css-prefix}-sys-find-next:before { content: sap-content($sap-var-sys-find-next); }
.#{$sap-css-prefix}-sys-find:before { content: sap-content($sap-var-sys-find); }
.#{$sap-css-prefix}-sys-monitor:before { content: sap-content($sap-var-sys-monitor); }
.#{$sap-css-prefix}-sys-prev-page:before { content: sap-content($sap-var-sys-prev-page); }
.#{$sap-css-prefix}-sys-first-page:before { content: sap-content($sap-var-sys-first-page); }
.#{$sap-css-prefix}-sys-next-page:before { content: sap-content($sap-var-sys-next-page); }
.#{$sap-css-prefix}-sys-last-page:before { content: sap-content($sap-var-sys-last-page); }
.#{$sap-css-prefix}-generate-shortcut:before { content: sap-content($sap-var-generate-shortcut); }
.#{$sap-css-prefix}-create-session:before { content: sap-content($sap-var-create-session); }
.#{$sap-css-prefix}-display-more:before { content: sap-content($sap-var-display-more); }
.#{$sap-css-prefix}-enter-more:before { content: sap-content($sap-var-enter-more); }
.#{$sap-css-prefix}-zoom-in:before { content: sap-content($sap-var-zoom-in); }
.#{$sap-css-prefix}-zoom-out:before { content: sap-content($sap-var-zoom-out); }
.#{$sap-css-prefix}-header:before { content: sap-content($sap-var-header); }
.#{$sap-css-prefix}-detail-view:before { content: sap-content($sap-var-detail-view); }
.#{$sap-css-prefix}-collapse:before { content: sap-content($sap-var-collapse); }
.#{$sap-css-prefix}-expand:before { content: sap-content($sap-var-expand); }
.#{$sap-css-prefix}-positive:before { content: sap-content($sap-var-positive); }
.#{$sap-css-prefix}-negative:before { content: sap-content($sap-var-negative); }
.#{$sap-css-prefix}-display:before { content: sap-content($sap-var-display); }
.#{$sap-css-prefix}-menu2:before { content: sap-content($sap-var-menu2); }
.#{$sap-css-prefix}-redo:before { content: sap-content($sap-var-redo); }
.#{$sap-css-prefix}-undo:before { content: sap-content($sap-var-undo); }
.#{$sap-css-prefix}-navigation-up-arrow:before { content: sap-content($sap-var-navigation-up-arrow); }
.#{$sap-css-prefix}-navigation-down-arrow:before { content: sap-content($sap-var-navigation-down-arrow); }
.#{$sap-css-prefix}-down:before { content: sap-content($sap-var-down); }
.#{$sap-css-prefix}-up:before { content: sap-content($sap-var-up); }
.#{$sap-css-prefix}-shelf:before { content: sap-content($sap-var-shelf); }
.#{$sap-css-prefix}-background:before { content: sap-content($sap-var-background); }
.#{$sap-css-prefix}-resize:before { content: sap-content($sap-var-resize); }
.#{$sap-css-prefix}-move:before { content: sap-content($sap-var-move); }
.#{$sap-css-prefix}-show:before { content: sap-content($sap-var-show); }
.#{$sap-css-prefix}-hide:before { content: sap-content($sap-var-hide); }
.#{$sap-css-prefix}-nav-back:before { content: sap-content($sap-var-nav-back); }
.#{$sap-css-prefix}-error:before { content: sap-content($sap-var-error); }
.#{$sap-css-prefix}-slim-arrow-right:before { content: sap-content($sap-var-slim-arrow-right); }
.#{$sap-css-prefix}-slim-arrow-left:before { content: sap-content($sap-var-slim-arrow-left); }
.#{$sap-css-prefix}-slim-arrow-down:before { content: sap-content($sap-var-slim-arrow-down); }
.#{$sap-css-prefix}-slim-arrow-up:before { content: sap-content($sap-var-slim-arrow-up); }
.#{$sap-css-prefix}-forward:before { content: sap-content($sap-var-forward); }
.#{$sap-css-prefix}-overflow:before { content: sap-content($sap-var-overflow); }
.#{$sap-css-prefix}-value-help:before { content: sap-content($sap-var-value-help); }
.#{$sap-css-prefix}-multi-select:before { content: sap-content($sap-var-multi-select); }
.#{$sap-css-prefix}-exit-full-screen:before { content: sap-content($sap-var-exit-full-screen); }
.#{$sap-css-prefix}-sys-add:before { content: sap-content($sap-var-sys-add); }
.#{$sap-css-prefix}-sys-minus:before { content: sap-content($sap-var-sys-minus); }
.#{$sap-css-prefix}-dropdown:before { content: sap-content($sap-var-dropdown); }
.#{$sap-css-prefix}-expand-group:before { content: sap-content($sap-var-expand-group); }
.#{$sap-css-prefix}-vertical-grip:before { content: sap-content($sap-var-vertical-grip); }
.#{$sap-css-prefix}-horizontal-grip:before { content: sap-content($sap-var-horizontal-grip); }
.#{$sap-css-prefix}-sort-descending:before { content: sap-content($sap-var-sort-descending); }
.#{$sap-css-prefix}-sort-ascending:before { content: sap-content($sap-var-sort-ascending); }
.#{$sap-css-prefix}-arrow-down:before { content: sap-content($sap-var-arrow-down); }
.#{$sap-css-prefix}-legend:before { content: sap-content($sap-var-legend); }
.#{$sap-css-prefix}-collapse-group:before { content: sap-content($sap-var-collapse-group); }
.#{$sap-css-prefix}-message-warning:before { content: sap-content($sap-var-message-warning); }
.#{$sap-css-prefix}-message-information:before { content: sap-content($sap-var-message-information); }
.#{$sap-css-prefix}-message-success:before { content: sap-content($sap-var-message-success); }
.#{$sap-css-prefix}-restart:before { content: sap-content($sap-var-restart); }
.#{$sap-css-prefix}-stop:before { content: sap-content($sap-var-stop); }
.#{$sap-css-prefix}-add-process:before { content: sap-content($sap-var-add-process); }
.#{$sap-css-prefix}-cancel-maintenance:before { content: sap-content($sap-var-cancel-maintenance); }
.#{$sap-css-prefix}-activate:before { content: sap-content($sap-var-activate); }
.#{$sap-css-prefix}-resize-horizontal:before { content: sap-content($sap-var-resize-horizontal); }
.#{$sap-css-prefix}-resize-vertical:before { content: sap-content($sap-var-resize-vertical); }
.#{$sap-css-prefix}-connected:before { content: sap-content($sap-var-connected); }
.#{$sap-css-prefix}-disconnected:before { content: sap-content($sap-var-disconnected); }
.#{$sap-css-prefix}-edit-outside:before { content: sap-content($sap-var-edit-outside); }
.#{$sap-css-prefix}-key:before { content: sap-content($sap-var-key); }
.#{$sap-css-prefix}-minimize:before { content: sap-content($sap-var-minimize); }
.#{$sap-css-prefix}-back-to-top:before { content: sap-content($sap-var-back-to-top); }
.#{$sap-css-prefix}-hello-world:before { content: sap-content($sap-var-hello-world); }
.#{$sap-css-prefix}-outbox:before { content: sap-content($sap-var-outbox); }
.#{$sap-css-prefix}-donut-chart:before { content: sap-content($sap-var-donut-chart); }
.#{$sap-css-prefix}-heatmap-chart:before { content: sap-content($sap-var-heatmap-chart); }
.#{$sap-css-prefix}-horizontal-bullet-chart:before { content: sap-content($sap-var-horizontal-bullet-chart); }
.#{$sap-css-prefix}-vertical-bullet-chart:before { content: sap-content($sap-var-vertical-bullet-chart); }
.#{$sap-css-prefix}-call:before { content: sap-content($sap-var-call); }
.#{$sap-css-prefix}-download-from-cloud:before { content: sap-content($sap-var-download-from-cloud); }
.#{$sap-css-prefix}-upload-to-cloud:before { content: sap-content($sap-var-upload-to-cloud); }
.#{$sap-css-prefix}-jam:before { content: sap-content($sap-var-jam); }
.#{$sap-css-prefix}-sap-ui5:before { content: sap-content($sap-var-sap-ui5); }
.#{$sap-css-prefix}-message-popup:before { content: sap-content($sap-var-message-popup); }
.#{$sap-css-prefix}-cloud:before { content: sap-content($sap-var-cloud); }
.#{$sap-css-prefix}-horizontal-waterfall-chart:before { content: sap-content($sap-var-horizontal-waterfall-chart); }
.#{$sap-css-prefix}-vertical-waterfall-chart:before { content: sap-content($sap-var-vertical-waterfall-chart); }
.#{$sap-css-prefix}-broken-link:before { content: sap-content($sap-var-broken-link); }
.#{$sap-css-prefix}-headset:before { content: sap-content($sap-var-headset); }
.#{$sap-css-prefix}-thumb-up:before { content: sap-content($sap-var-thumb-up); }
.#{$sap-css-prefix}-thumb-down:before { content: sap-content($sap-var-thumb-down); }
.#{$sap-css-prefix}-multiselect-all:before { content: sap-content($sap-var-multiselect-all); }
.#{$sap-css-prefix}-multiselect-none:before { content: sap-content($sap-var-multiselect-none); }
.#{$sap-css-prefix}-scissors:before { content: sap-content($sap-var-scissors); }
.#{$sap-css-prefix}-sound:before { content: sap-content($sap-var-sound); }
.#{$sap-css-prefix}-sound-loud:before { content: sap-content($sap-var-sound-loud); }
.#{$sap-css-prefix}-sound-off:before { content: sap-content($sap-var-sound-off); }
.#{$sap-css-prefix}-date-time:before { content: sap-content($sap-var-date-time); }
.#{$sap-css-prefix}-user-settings:before { content: sap-content($sap-var-user-settings); }
.#{$sap-css-prefix}-key-user-settings:before { content: sap-content($sap-var-key-user-settings); }
.#{$sap-css-prefix}-developer-settings:before { content: sap-content($sap-var-developer-settings); }
.#{$sap-css-prefix}-text-formatting:before { content: sap-content($sap-var-text-formatting); }
.#{$sap-css-prefix}-bold-text:before { content: sap-content($sap-var-bold-text); }
.#{$sap-css-prefix}-italic-text:before { content: sap-content($sap-var-italic-text); }
.#{$sap-css-prefix}-underline-text:before { content: sap-content($sap-var-underline-text); }
.#{$sap-css-prefix}-text-align-justified:before { content: sap-content($sap-var-text-align-justified); }
.#{$sap-css-prefix}-text-align-left:before { content: sap-content($sap-var-text-align-left); }
.#{$sap-css-prefix}-text-align-center:before { content: sap-content($sap-var-text-align-center); }
.#{$sap-css-prefix}-text-align-right:before { content: sap-content($sap-var-text-align-right); }
.#{$sap-css-prefix}-bullet-text:before { content: sap-content($sap-var-bullet-text); }
.#{$sap-css-prefix}-numbered-text:before { content: sap-content($sap-var-numbered-text); }
.#{$sap-css-prefix}-co:before { content: sap-content($sap-var-co); }
.#{$sap-css-prefix}-ui-notifications:before { content: sap-content($sap-var-ui-notifications); }
.#{$sap-css-prefix}-bell:before { content: sap-content($sap-var-bell); }
.#{$sap-css-prefix}-cancel-share:before { content: sap-content($sap-var-cancel-share); }
.#{$sap-css-prefix}-write-new-document:before { content: sap-content($sap-var-write-new-document); }
.#{$sap-css-prefix}-write-new:before { content: sap-content($sap-var-write-new); }
.#{$sap-css-prefix}-cancel:before { content: sap-content($sap-var-cancel); }
.#{$sap-css-prefix}-screen-split-one:before { content: sap-content($sap-var-screen-split-one); }
.#{$sap-css-prefix}-screen-split-two:before { content: sap-content($sap-var-screen-split-two); }
.#{$sap-css-prefix}-screen-split-three:before { content: sap-content($sap-var-screen-split-three); }
.#{$sap-css-prefix}-customize:before { content: sap-content($sap-var-customize); }
.#{$sap-css-prefix}-user-edit:before { content: sap-content($sap-var-user-edit); }
.#{$sap-css-prefix}-source-code:before { content: sap-content($sap-var-source-code); }
.#{$sap-css-prefix}-copy:before { content: sap-content($sap-var-copy); }
.#{$sap-css-prefix}-paste:before { content: sap-content($sap-var-paste); }
.#{$sap-css-prefix}-line-chart-time-axis:before { content: sap-content($sap-var-line-chart-time-axis); }
.#{$sap-css-prefix}-clear-filter:before { content: sap-content($sap-var-clear-filter); }
.#{$sap-css-prefix}-reset:before { content: sap-content($sap-var-reset); }
.#{$sap-css-prefix}-trend-up:before { content: sap-content($sap-var-trend-up); }
.#{$sap-css-prefix}-trend-down:before { content: sap-content($sap-var-trend-down); }
.#{$sap-css-prefix}-cursor-arrow:before { content: sap-content($sap-var-cursor-arrow); }
.#{$sap-css-prefix}-add-document:before { content: sap-content($sap-var-add-document); }
.#{$sap-css-prefix}-create-form:before { content: sap-content($sap-var-create-form); }
.#{$sap-css-prefix}-resize-corner:before { content: sap-content($sap-var-resize-corner); }
.#{$sap-css-prefix}-chevron-phase:before { content: sap-content($sap-var-chevron-phase); }
.#{$sap-css-prefix}-chevron-phase-2:before { content: sap-content($sap-var-chevron-phase-2); }
.#{$sap-css-prefix}-rhombus-milestone:before { content: sap-content($sap-var-rhombus-milestone); }
.#{$sap-css-prefix}-rhombus-milestone-2:before { content: sap-content($sap-var-rhombus-milestone-2); }
.#{$sap-css-prefix}-circle-task:before { content: sap-content($sap-var-circle-task); }
.#{$sap-css-prefix}-circle-task-2:before { content: sap-content($sap-var-circle-task-2); }
.#{$sap-css-prefix}-project-definition-triangle:before { content: sap-content($sap-var-project-definition-triangle); }
.#{$sap-css-prefix}-project-definition-triangle-2:before { content: sap-content($sap-var-project-definition-triangle-2); }
.#{$sap-css-prefix}-master-task-triangle:before { content: sap-content($sap-var-master-task-triangle); }
.#{$sap-css-prefix}-master-task-triangle-2:before { content: sap-content($sap-var-master-task-triangle-2); }
.#{$sap-css-prefix}-program-triangles:before { content: sap-content($sap-var-program-triangles); }
.#{$sap-css-prefix}-program-triangles-2:before { content: sap-content($sap-var-program-triangles-2); }
.#{$sap-css-prefix}-mirrored-task-circle:before { content: sap-content($sap-var-mirrored-task-circle); }
.#{$sap-css-prefix}-mirrored-task-circle-2:before { content: sap-content($sap-var-mirrored-task-circle-2); }
.#{$sap-css-prefix}-checklist-item:before { content: sap-content($sap-var-checklist-item); }
.#{$sap-css-prefix}-checklist-item-2:before { content: sap-content($sap-var-checklist-item-2); }
.#{$sap-css-prefix}-checklist:before { content: sap-content($sap-var-checklist); }
.#{$sap-css-prefix}-checklist-2:before { content: sap-content($sap-var-checklist-2); }
.#{$sap-css-prefix}-chart-table-view:before { content: sap-content($sap-var-chart-table-view); }
.#{$sap-css-prefix}-filter-analytics:before { content: sap-content($sap-var-filter-analytics); }
.#{$sap-css-prefix}-filter-facets:before { content: sap-content($sap-var-filter-facets); }
.#{$sap-css-prefix}-filter-fields:before { content: sap-content($sap-var-filter-fields); }
.#{$sap-css-prefix}-indent:before { content: sap-content($sap-var-indent); }
.#{$sap-css-prefix}-outdent:before { content: sap-content($sap-var-outdent); }
.#{$sap-css-prefix}-heading1:before { content: sap-content($sap-var-heading1); }
.#{$sap-css-prefix}-heading2:before { content: sap-content($sap-var-heading2); }
.#{$sap-css-prefix}-heading3:before { content: sap-content($sap-var-heading3); }
.#{$sap-css-prefix}-decrease-line-height:before { content: sap-content($sap-var-decrease-line-height); }
.#{$sap-css-prefix}-increase-line-height:before { content: sap-content($sap-var-increase-line-height); }
.#{$sap-css-prefix}-fx:before { content: sap-content($sap-var-fx); }
.#{$sap-css-prefix}-add-folder:before { content: sap-content($sap-var-add-folder); }
.#{$sap-css-prefix}-away:before { content: sap-content($sap-var-away); }
.#{$sap-css-prefix}-busy:before { content: sap-content($sap-var-busy); }
.#{$sap-css-prefix}-appear-offline:before { content: sap-content($sap-var-appear-offline); }
.#{$sap-css-prefix}-blur:before { content: sap-content($sap-var-blur); }
.#{$sap-css-prefix}-pixelate:before { content: sap-content($sap-var-pixelate); }
.#{$sap-css-prefix}-horizontal-combination-chart:before { content: sap-content($sap-var-horizontal-combination-chart); }
.#{$sap-css-prefix}-add-employee:before { content: sap-content($sap-var-add-employee); }
.#{$sap-css-prefix}-text-color:before { content: sap-content($sap-var-text-color); }
.#{$sap-css-prefix}-browse-folder:before { content: sap-content($sap-var-browse-folder); }
.#{$sap-css-prefix}-primary-key:before { content: sap-content($sap-var-primary-key); }
.#{$sap-css-prefix}-two-keys:before { content: sap-content($sap-var-two-keys); }
.#{$sap-css-prefix}-strikethrough:before { content: sap-content($sap-var-strikethrough); }
.#{$sap-css-prefix}-text:before { content: sap-content($sap-var-text); }
.#{$sap-css-prefix}-responsive:before { content: sap-content($sap-var-responsive); }
.#{$sap-css-prefix}-desktop-mobile:before { content: sap-content($sap-var-desktop-mobile); }
.#{$sap-css-prefix}-table-row:before { content: sap-content($sap-var-table-row); }
.#{$sap-css-prefix}-table-column:before { content: sap-content($sap-var-table-column); }
.#{$sap-css-prefix}-validate:before { content: sap-content($sap-var-validate); }
.#{$sap-css-prefix}-keyboard-and-mouse:before { content: sap-content($sap-var-keyboard-and-mouse); }
.#{$sap-css-prefix}-touch:before { content: sap-content($sap-var-touch); }